import React, { type ComponentType, type ReactNode } from 'react';
import './login.css';
import { AuthCheck, useAuth, useUser } from 'reactfire';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { type GoogleUser } from './models';

export function Login(): React.JSX.Element | null {
  const auth = useAuth();
  const user = useUser();
  const signIn = (): void => {
    void signInWithPopup(auth, new GoogleAuthProvider());
  };
  if (!user.hasEmitted) return null;
  return (
    <div className="login-required">
      <button type="button" onClick={signIn} />
    </div>
  );
}

// export const clearFirestoreCache = () => {
//   const map: Map<string, unknown> = (globalThis as any)[
//     '_reactFirePreloadedObservables'
//   ];
//   Array.from(map.keys()).forEach(
//     (key: string) => key.includes('firestore') && map.delete(key)
//   );
// };

// export const logout = (auth: Auth, firestore: Firestore) => {
//   auth.signOut().then(() => {
//     window.location.reload();
//   });
// };

export function AuthWrapper({
  Component,
  fallback,
}: {
  Component: ComponentType<{ user: GoogleUser }>;
  fallback: JSX.Element;
}): ReactNode {
  const { status, data } = useUser();

  if (status === 'success' && data) {
    // eslint-disable-next-line no-console -- debug
    console.log(JSON.stringify(data));
    return <Component user={{ id: data.uid, isAnonymous: false }} />;
  }
  return fallback;
}

export function MaybeLogout(): React.JSX.Element {
  // const auth = useAuth();
  // const firestore = useFirestore();
  const user = useUser();
  const photoURL: string | null = user.data?.photoURL ?? null;
  return (
    <AuthCheck fallback={null}>
      <div className="logout">
        {photoURL ? (
          <button
            type="button"
            className="avatar"
            style={{
              backgroundImage: `url(${photoURL})`,
            }}
          />
        ) : null}
        <button
          type="button"
          onClick={() => {
            // eslint-disable-next-line no-alert -- unimplmented
            alert('logout');
          }}
        >
          Logout
        </button>
      </div>
    </AuthCheck>
  );
}
