import { collection, query, where } from 'firebase/firestore';
import React from 'react';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire';
import { type User } from 'firebase/auth';
import { guardedTeams } from './models';
import { Tile } from './tile';

export function TeamList(): React.JSX.Element | null {
  const user = useUser();
  if (user.status !== 'success' || user.data === null) {
    return null;
  }
  return <TeamListWithUser user={user.data} />;
}

interface TeamListWithUserProps {
  user: User;
}
export function TeamListWithUser({
  user,
}: TeamListWithUserProps): React.JSX.Element {
  const firestore = useFirestore();
  const gameCollection = collection(firestore, 'team');
  const teamQuery = user.uid
    ? query(gameCollection, where('owner', '==', user.uid))
    : gameCollection;
  const {
    status,
    data: teams,
    error,
  } = useFirestoreCollectionData(teamQuery, {
    idField: 'id',
  });

  if (status === 'error') {
    return <div>Error {error?.message}</div>;
  }
  if (status !== 'success') return <div />;
  const goodTeams = guardedTeams(teams);
  const orderedTeams = goodTeams.sort((a, b) => {
    if (b.name > a.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <Tile>
      {orderedTeams.map((team) => (
        <div
          key={team.id}
          style={{
            minHeight: '100%',
            height: '100%',
            width: '100%',
            backgroundColor: 'black',
            color: 'white',
          }}
        >
          {team.name}
        </div>
      ))}
    </Tile>
  );
}
