import { z } from 'zod';
import { SideSchema } from '../models';

export const ScoreEventSchema = z.object({
  cat: z.literal('ScoreEvent'),
  side: SideSchema,
  player: z.string(),
  points: z.number(),
});
export type ScoreEvent = z.infer<typeof ScoreEventSchema>;

export const FreeThrowMakeEventSchema = ScoreEventSchema.extend({
  points: z.literal(1),
  what: z.literal('FreeThrowMake'),
});
export type FreeThrowMakeEvent = z.infer<typeof FreeThrowMakeEventSchema>;

export const TwoPointMakeEventSchema = ScoreEventSchema.extend({
  assistedBy: z.string().optional(),
  points: z.literal(2),
  what: z.literal('TwoPointMake'),
});
export type TwoPointMakeEvent = z.infer<typeof TwoPointMakeEventSchema>;

export const ThreePointMakeEventSchema = ScoreEventSchema.extend({
  assistedBy: z.string().optional(),
  points: z.literal(3),
  what: z.literal('ThreePointMake'),
});
export type ThreePointMakeEvent = z.infer<typeof ThreePointMakeEventSchema>;

export const ScoreEventsSchema = z.union([
  FreeThrowMakeEventSchema,
  TwoPointMakeEventSchema,
  ThreePointMakeEventSchema,
]);
export type ScoreEvents = z.infer<typeof ScoreEventsSchema>;
