import { z } from 'zod';
import { humanTime } from '../time';
import { FoulEventsSchema } from './foul-events';
import { type TimedEvent } from './models';
import { ScoreEventsSchema } from './score-event';
import { ReboundEventsSchema } from './rebound-events';
import { BlockEventSchema } from './block-events';
import { MissEventsSchema } from './miss-events';
import { QuarterEventsSchema } from './quarter-event';
import { ClockEventsSchema } from './clock';
import { TurnoverEventsSchema } from './turnover-events';

export const EventSchema = FoulEventsSchema.or(ScoreEventsSchema)
  .or(MissEventsSchema)
  .or(BlockEventSchema)
  .or(ReboundEventsSchema)
  .or(QuarterEventsSchema)
  .or(ClockEventsSchema)
  .or(TurnoverEventsSchema);

export type Event = z.infer<typeof EventSchema>;
export const eventToHumanTime = (event: TimedEvent): string => {
  return humanTime(Math.round(event.when / 1000));
};

export const SavedEventSchema = z.intersection(
  z.object({
    id: z.string(),
    when: z.number(),
    deleted: z.boolean().optional(),
  }),
  EventSchema,
);
export type SavedEvent = z.infer<typeof SavedEventSchema>;
