import React from 'react';
import {
  type ActionComponentBaseProps,
  ActionComponent,
} from '../action-component';
import { type GameTeamI, type PlayerI, type Side } from '../../models';
import { PlayerSelect } from '../../game/player';

export function FreeThrowMiss(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  const playerSelected: (_team: GameTeamI, _gamePlayer: PlayerI) => void = (
    team,
    gamePlayer,
  ) => {
    const side: Side = team === props.home ? 'home' : 'away';
    void props
      .insertEvent({
        cat: 'MissEvent',
        what: 'FreeThrowMiss',
        player: gamePlayer.id,
        points: 1,
        side,
      })
      .then(() => {
        props.doneCallback();
      });
  };
  return (
    <ActionComponent
      {...props}
      label="😲 Missed Freethrow"
      shortcut={{ key: 'F' }}
    >
      <PlayerSelect setPlayer={playerSelected} {...props} />
    </ActionComponent>
  );
}

export function TwoPointMiss(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  const playerSelected: (_team: GameTeamI, _gamePlayer: PlayerI) => void = (
    team,
    gamePlayer,
  ) => {
    const side: Side = team === props.home ? 'home' : 'away';
    void props
      .insertEvent({
        cat: 'MissEvent',
        what: 'TwoPointMiss',
        side,
        player: gamePlayer.id,
        points: 2,
      })
      .then(() => {
        props.doneCallback();
      });
  };
  return (
    <ActionComponent
      {...props}
      label="😲😲 Missed Two Points"
      shortcut={{ code: 'Digit2', shift: true }}
    >
      <PlayerSelect setPlayer={playerSelected} {...props} />
    </ActionComponent>
  );
}

export function ThreePointMiss(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  const playerSelected: (_team: GameTeamI, _gamePlayer: PlayerI) => void = (
    team,
    gamePlayer,
  ) => {
    const side: Side = team === props.home ? 'home' : 'away';
    void props
      .insertEvent({
        cat: 'MissEvent',
        what: 'ThreePointMiss',
        side,
        player: gamePlayer.id,
        points: 3,
      })
      .then(() => {
        props.doneCallback();
      });
  };
  return (
    <ActionComponent
      {...props}
      label="😲😲😲 Missed Three Points"
      shortcut={{ code: 'Digit3', shift: true }}
    >
      <PlayerSelect setPlayer={playerSelected} {...props} />
    </ActionComponent>
  );
}
