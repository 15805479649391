import { z } from 'zod';
import { SideSchema } from '../models';

export const BlockEventSchema = z.object({
  cat: z.literal('BlockEvent'),
  what: z.literal('Block'),
  side: SideSchema,
  player: z.string(),
  blockedPlayer: z.string().optional(),
});
export type BlockEvent = z.infer<typeof BlockEventSchema>;
