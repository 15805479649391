import { z } from 'zod';
import { SideSchema } from '../models';

export const TurnoverEventSchema = z.object({
  cat: z.literal('TurnoverEvent'),
  side: SideSchema,
  player: z.string(),
  causedBy: z.string().optional(),
});
export type TurnoverEvent = z.infer<typeof TurnoverEventSchema>;

export const GeneralTurnoverSchema = TurnoverEventSchema.extend({
  what: z.literal('GeneralTurnover'),
});
export type GeneralTurnover = z.infer<typeof GeneralTurnoverSchema>;

export const BallhandlingSchema = TurnoverEventSchema.extend({
  what: z.literal('Ballhandling'),
});
export type BallHandling = z.infer<typeof BallhandlingSchema>;

export const TravelingSchema = TurnoverEventSchema.extend({
  what: z.literal('Traveling'),
});
export type Traveling = z.infer<typeof TravelingSchema>;

export const StealSchema = TurnoverEventSchema.extend({
  what: z.literal('Steal'),
});

export const TurnoverEventsSchema = z.union([
  GeneralTurnoverSchema,
  BallhandlingSchema,
  TravelingSchema,
  StealSchema,
]);
export type TurnoverEvents = z.infer<typeof TurnoverEventsSchema>;
