import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { type GameTeamI } from '../models';
import { type Event } from '../event/event';
import { humanTime } from '../time';

export interface ActionComponentProps extends ActionComponentBaseProps {
  label: string;
}

interface ShortcutCode {
  code: string;
  shift: boolean;
}

interface ShortcutKey {
  key: string;
}

export type Shortcut = ShortcutCode | ShortcutKey;

export interface ActionComponentBaseProps {
  activeCallback: () => void;
  doneCallback: () => void;
  insertEvent: (event: Event) => Promise<void>;
  when: number;
  home: GameTeamI;
  away: GameTeamI;
  gameID: string;
  currentEventID: string;
  shortcut?: Shortcut;
  shortcutEnabled: boolean;
  setShortcutEnabled: (shortcutEnabled: boolean) => void;
  children?: React.ReactNode;
}

export function ActionComponent({
  label,
  doneCallback,
  activeCallback,
  children,
  when,
  shortcut,
  shortcutEnabled: shortCutEnabled,
}: ActionComponentProps): React.JSX.Element {
  const [active, setActive] = useState<boolean>(false);
  const keyPress = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        doneCallback();
        setActive(false);
      }
      // We don't disable Escape
      if (!shortCutEnabled) return;

      if (shortcut === undefined) return;
      if ('code' in shortcut) {
        if (ev.code !== shortcut.code) return;
        if (ev.shiftKey !== shortcut.shift) return;
      } else if (ev.key !== shortcut.key) return;
      setActive(true);
      activeCallback();
    },
    [setActive, activeCallback, shortcut, shortCutEnabled, doneCallback],
  );

  useEffect(() => {
    window.addEventListener('keyup', keyPress);
    return () => {
      window.removeEventListener('keyup', keyPress);
    };
  }, [keyPress]);

  if (active) {
    return (
      <>
        <div>
          <div className="active">
            <div data-testid="label">{label}</div>
            <div className="when">{humanTime(when / 1000)}</div>
            <button
              type="button"
              className="link-button cancel"
              data-testid="cancel"
              onClick={() => {
                doneCallback();
                setActive(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        {!isValidElement(children) ? children : cloneElement(children)}
      </>
    );
  }
  return (
    <div className="action">
      <button
        type="button"
        data-testid="activate"
        onClick={() => {
          setActive(true);
          activeCallback();
        }}
      >
        {label.toLocaleUpperCase()}
      </button>
    </div>
  );
}
