import React, { Component, type ReactNode } from 'react';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: {
    hasError: boolean;
  };
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): unknown {
    // Update state so the next render will show the fallback UI.
    // {"code":"permission-denied","name":"FirebaseError"}
    if ('name' in error && error.name === 'FirebaseError') {
      if ('code' in error && error.code === 'permission-denied') {
        return { hasError: false };
      }
    }
    // eslint-disable-next-line no-console -- deal with it later
    console.log(JSON.stringify(error));
    return { hasError: true };
  }
  componentDidCatch(error: unknown, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console -- make this nice
    console.log(`friggin villa`, error, errorInfo);
  }
  render():
    | string
    | number
    | boolean
    | Iterable<React.ReactNode>
    | React.JSX.Element
    | null
    | undefined {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
