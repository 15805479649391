import React from 'react';
import './steppedprogress.css';

interface SteppedProgressProps {
  steps: string[];
  currentStep: number;
}
export function SteppedProgress({
  currentStep,
  steps,
}: SteppedProgressProps): React.JSX.Element {
  const getClass = (activeStep: number, compareStep: number): string => {
    if (activeStep === compareStep) {
      return 'active';
    }
    if (activeStep > compareStep) {
      return 'done';
    }
    return '';
  };
  const listEntries = steps.map((step, idx) => {
    return (
      <li key={step} className={getClass(currentStep, idx + 1)}>
        {step}
      </li>
    );
  });

  return (
    <div className="steppedprogress-container clearfix">
      <ul className="steppedprogress">{listEntries}</ul>
    </div>
  );
}
