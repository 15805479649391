import React, { useState } from 'react';
import { useFirestore } from 'reactfire';
import { type SavedEvent, eventToHumanTime } from '../event/event';
import './event-viewer.css';
import { type PlayerI, type GameTeamI, type Side } from '../models';
import { deleteEvent, useGameEvents } from '../state';

interface EventViewerDetail {
  what: string;
  who?: PlayerI;
  side?: Side;
}

interface EventViewerItem {
  skip?: boolean;
  event?: EventViewerDetail;
  secondaryEvent?: EventViewerDetail;
  side?: Side;
}

const capitalize = (input: string | undefined): string | undefined => {
  if (input === undefined) return input;
  return input.charAt(0).toUpperCase() + input.slice(1);
};

const eventDetail = (
  event: SavedEvent,
  getPlayer: (playerID: string) => PlayerI | undefined,
): EventViewerItem => {
  switch (event.cat) {
    case 'BlockEvent':
      switch (event.what) {
        case 'Block':
          if (event.blockedPlayer === undefined) {
            return {
              event: {
                what: 'Block',
                who: getPlayer(event.player),
              },
              side: event.side,
            };
          }
          return {
            event: {
              what: 'Block',
              who: getPlayer(event.player),
            },
            secondaryEvent: {
              what: 'Blocked',
              who: getPlayer(event.blockedPlayer),
            },
          };
      }
      return {};
    case 'ClockEvent':
      return {};
    case 'FoulEvent':
      switch (event.what) {
        case 'PersonalFoul':
          if (event.fouledOn === undefined) {
            return {
              event: {
                what: 'Foul',
                who: getPlayer(event.player),
              },
              side: event.side,
            };
          }
          return {
            event: {
              what: 'Foul',
              who: getPlayer(event.player),
            },
            secondaryEvent: {
              what: 'Foul on',
              who: getPlayer(event.fouledOn),
            },
            side: event.side,
          };
      }
      return {};
    case 'MissEvent':
      switch (event.what) {
        case 'FreeThrowMiss':
          return {
            side: event.side,
            event: {
              what: 'Freethrow Missed',
              who: getPlayer(event.player),
            },
          };
        case 'TwoPointMiss':
          return {
            side: event.side,
            event: {
              what: 'Missed Two 😲',
              who: getPlayer(event.player),
            },
          };
        case 'ThreePointMiss':
          return {
            side: event.side,
            event: {
              what: 'Missed Three 🥴',
              who: getPlayer(event.player),
            },
          };
      }
      return {};
    case 'QuarterEvent':
      return {};
    case 'ReboundEvent':
      switch (event.what) {
        case 'DefensiveRebound':
          return {
            side: event.side,
            event: {
              what: 'Rebound',
              who: getPlayer(event.player),
            },
          };
        case 'OffensiveRebound':
          return {
            side: event.side,
            event: {
              what: 'Offensive Rebound',
              who: getPlayer(event.player),
            },
          };
      }
      return {};
    case 'ScoreEvent':
      switch (event.what) {
        case 'FreeThrowMake': {
          return {
            side: event.side,
            event: {
              what: 'Freethrow',
              who: getPlayer(event.player),
            },
          };
        }
        case 'TwoPointMake':
          if (event.assistedBy === undefined) {
            return {
              side: event.side,
              event: {
                what: '✌️ Two Points',
                who: getPlayer(event.player),
              },
            };
          }
          return {
            side: event.side,
            event: {
              what: '✌️ Two Points',
              who: getPlayer(event.player),
            },
            secondaryEvent: {
              what: 'Assist',
              who: getPlayer(event.assistedBy),
            },
          };

        case 'ThreePointMake':
          if (event.assistedBy === undefined) {
            return {
              side: event.side,
              event: {
                what: '👌 Three Points',
                who: getPlayer(event.player),
              },
            };
          }
          return {
            side: event.side,
            event: {
              what: '👌 Three Points',
              who: getPlayer(event.player),
            },
            secondaryEvent: {
              what: 'Assist',
              who: getPlayer(event.assistedBy),
            },
          };
      }
      return {};
    case 'TurnoverEvent':
      switch (event.what) {
        case 'GeneralTurnover':
          return {
            side: event.side,
            event: {
              what: 'Turnover',
              who: getPlayer(event.player),
            },
          };
        case 'Ballhandling':
          return {
            side: event.side,
            event: {
              what: 'Turnover (ballhandling)',
              who: getPlayer(event.player),
            },
          };
        case 'Traveling':
          return {
            side: event.side,
            event: {
              what: 'Turnover (travelling)',
              who: getPlayer(event.player),
            },
          };
        case 'Steal': {
          if (event.causedBy === undefined) return {};
          const stolenFrom = getPlayer(event.player);
          return {
            side: event.side === 'home' ? 'away' : 'home',
            event: {
              what: 'Steal',
              who: getPlayer(event.causedBy),
              side: event.side === 'home' ? 'away' : 'home',
            },
            secondaryEvent: stolenFrom && {
              who: stolenFrom,
              what: 'from',
              side: event.side,
            },
          };
        }
      }
      return {};
  }
};

function EventViewerSingleEventRow({
  event,
  seekTo,
  detail,
  gameID,
}: {
  gameID: string;
  event: SavedEvent;
  seekTo: (where: number) => void;
  detail: EventViewerItem;
}): React.JSX.Element {
  const firestore = useFirestore();

  return (
    <tr className={detail.side}>
      <td className="side home">{detail.side === 'home' && 'HOME'}</td>
      <td>
        <button
          type="button"
          title="Jump to this event"
          className="link-button"
          onClick={() => {
            seekTo(Math.round(event.when / 1000) - 6);
          }}
        >
          {eventToHumanTime(event)}
        </button>
      </td>
      <td className="what">
        <div>{detail.event?.what ?? ''}</div>
        {detail.secondaryEvent !== undefined && (
          <div className="secondary">{detail.secondaryEvent.what}</div>
        )}
      </td>
      <td className="nr">
        <div>{detail.event?.who?.number ?? ''}</div>
        {detail.secondaryEvent ? (
          <div className="secondary">
            {detail.secondaryEvent.who?.number ?? ''}
          </div>
        ) : null}
      </td>
      <td className="name">
        {detail.event?.who?.name ?? capitalize(detail.event?.side) ?? ''}
        {detail.secondaryEvent ? (
          <div className="secondary">
            {detail.secondaryEvent.who?.name ??
              capitalize(detail.secondaryEvent.side) ??
              ''}
          </div>
        ) : null}
      </td>
      <td>
        <button
          type="button"
          title="Delete Event"
          className="link-button delete"
          onClick={() => {
            void deleteEvent(firestore, gameID, event);
          }}
        >
          ✗
        </button>
      </td>
      <td className="side away">{detail.side === 'away' && 'AWAY'}</td>
    </tr>
  );
}

interface EventViewProps {
  gameID: string;
  seekTo: (where: number) => void;
  homeTeam: GameTeamI;
  awayTeam: GameTeamI;
  positionSeconds: number;
  setInClipReplay: (replays: {
    where: number;
    timings: [number, number][];
  }) => void;
  setShortcutEnabled: (shortcutsEnabled: boolean) => void;
}
export function EventViewer(props: EventViewProps): React.JSX.Element | null {
  const [filters, setFilters] = useState<string[]>([]);

  const eventsService = useGameEvents(props.gameID);
  const posSeconds = props.positionSeconds;
  if (eventsService.status !== 'loaded') return null;
  const orderedEvents = eventsService.payload
    .sort((a, b) => {
      if (a.when > b.when) return -1;
      if (a.when < b.when) return 1;
      return 0;
    })
    .filter((event) => {
      return event.when < posSeconds * 1000;
    });

  const players = [...props.homeTeam.members, ...props.awayTeam.members];

  const eventsTimings: [number, number][] = [];

  const eventViewerRows = orderedEvents
    .map((event) => {
      const detail = eventDetail(event, (playerID: string) =>
        players.find((player) => player.id === playerID),
      );
      const searchEvent = (searchWord: string): boolean =>
        [
          detail.side,
          ...(detail.event?.what.split(' ') ?? []),
          detail.event?.who?.name,
          detail.event?.who?.number.toString(),
          ...(detail.secondaryEvent?.what.split(' ') ?? []),
          detail.secondaryEvent?.who?.name,
          detail.secondaryEvent?.who?.number.toString(),
        ].find((detailWord) =>
          detailWord?.toLowerCase().startsWith(searchWord.toLowerCase()),
        ) !== undefined;

      if (filters.length > 0) {
        if (
          filters
            .map((filter) => {
              if (filter.startsWith('!')) {
                return !searchEvent(filter.substr(1));
              }
              return searchEvent(filter);
            })
            .includes(false)
        )
          return;
        eventsTimings.unshift([
          Math.round(event.when / 1000) - 5,
          Math.round(event.when / 1000) + 2,
        ]);
      }
      return (
        <EventViewerSingleEventRow
          key={event.id}
          event={event}
          detail={detail}
          {...props}
        />
      );
    })
    .filter((item) => item !== undefined);
  return (
    <div className="event-viewer">
      <div className="header">
        <h3>Game Events</h3>
      </div>
      <div className="filter">
        <input
          name="filter"
          placeholder="Filter events, eg home points"
          onFocus={() => {
            props.setShortcutEnabled(false);
          }}
          onBlur={() => {
            props.setShortcutEnabled(true);
          }}
          onChange={(evt) => {
            setFilters(evt.target.value.toLowerCase().split(/  */));
          }}
        />
        <button
          type="button"
          className="link-button"
          disabled={
            filters.filter((a) => a !== '').length === 0 ||
            eventViewerRows.length === 0
          }
          onClick={() => {
            props.setInClipReplay({
              where: -1,
              timings: eventsTimings,
            });
          }}
        >
          Replay
        </button>
      </div>
      <div className="rows">
        <table>
          <tbody>{eventViewerRows}</tbody>
        </table>
      </div>
    </div>
  );
}
