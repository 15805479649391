import { useCallback, useEffect, useState } from 'react';

export type UseSize = (c: HTMLElement | undefined) => {
  width: number;
  height: number;
};

export const useSize: UseSize = (container?: HTMLElement) => {
  const [clientSize, setClientSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  const resize = useCallback(() => {
    if (container === undefined) return;
    setClientSize({
      width: container.clientWidth,
      height: container.clientHeight,
    });
  }, [container]);
  useEffect(() => {
    if (container === undefined) return;

    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [container, resize]);

  return clientSize;
};
