import { z } from 'zod';
import { SideSchema } from '../models';

export const MissEventSchema = z.object({
  cat: z.literal('MissEvent'),
  side: SideSchema,
  player: z.string(),
  points: z.number(),
});
export type MissEvent = z.infer<typeof MissEventSchema>;

export const FreeThrowMissEventSchema = MissEventSchema.extend({
  what: z.literal('FreeThrowMiss'),
  points: z.literal(1),
});
export type FreeThrowMissEvent = z.infer<typeof FreeThrowMissEventSchema>;

export const TwoPointMissEventSchema = MissEventSchema.extend({
  what: z.literal('TwoPointMiss'),
  points: z.literal(2),
});
export type TwoPointMissEvent = z.infer<typeof TwoPointMissEventSchema>;

export const ThreePointMissEventSchema = MissEventSchema.extend({
  what: z.literal('ThreePointMiss'),
  points: z.literal(3),
});
export type ThreePointMissEvent = z.infer<typeof ThreePointMissEventSchema>;

export const MissEventsSchema = z.union([
  FreeThrowMissEventSchema,
  TwoPointMissEventSchema,
  ThreePointMissEventSchema,
]);
export type MissEvents = z.infer<typeof MissEventsSchema>;
