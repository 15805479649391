import React, { useState, useEffect } from 'react';
import { type Side, type PlayerI, type GameTeamI } from '../models';
import { PlayerSelect, PlayerSelectOneTeam } from '../game/player';
import { type TurnoverEvents } from '../event/turnover-events';
import { type Event } from '../event/event';
import { type ActionComponentBaseProps } from './action-component';

interface PlayerCausedByProps extends ActionComponentBaseProps {
  label: string;
  causedByLabel: string;
  event: (side: Side, player: string, causedBy?: string) => TurnoverEvents;
  setShortcutEnabled: (shortcutEnabled: boolean) => void;
}

const causedBySelected: (
  team: GameTeamI,
  player: PlayerI,
  causedBy: PlayerI,
  side: Side,
  insertEvent: (event: Event) => Promise<void>,
  event: (side: Side, player: string, causedBy?: string) => TurnoverEvents,
  doneCallback: () => void,
  setPlayer: (player: PlayerI | undefined) => void,
) => void = (
  team,
  player,
  causedBy,
  side,
  insertEvent,
  event,
  doneCallback,
  setPlayer,
) => {
  insertEvent(event(side, player.id, causedBy.id))
    .then(() => {
      setPlayer(undefined);
      doneCallback();
    })
    .catch((e: unknown): void => {
      // eslint-disable-next-line no-console -- fle
      console.error(e);
    });
};

export function PlayerCausedBy(props: PlayerCausedByProps): React.JSX.Element {
  const {
    home,
    away,
    setShortcutEnabled,
    insertEvent,
    event,
    doneCallback,
    causedByLabel,
  } = props;
  const [saving, setSaving] = useState(false);
  const [player, setPlayer] = useState<PlayerI | undefined>(undefined);
  const [opposingTeam, setOpposingTeam] = useState<GameTeamI | undefined>(
    undefined,
  );
  const setGamePlayer = (team: GameTeamI, p: PlayerI): void => {
    setOpposingTeam(team === home ? away : home);
    setPlayer(p);
  };
  const [search, setSearch] = useState<string>('');
  const [inputRef, setInputRef] = useState<HTMLInputElement>();

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  useEffect(() => {
    setShortcutEnabled(false);
    return () => {
      setShortcutEnabled(true);
    };
  }, [setShortcutEnabled]);

  useEffect(() => {
    if (player === undefined || opposingTeam === undefined) {
      return;
    }
    if (saving) return;
    if (opposingTeam.members.length === 0) {
      setSaving(true);
      causedBySelected(
        opposingTeam,
        {
          number: 0,
          name: opposingTeam.name,
          id: '0',
        },
        player,
        opposingTeam === home ? 'home' : 'away',
        insertEvent,
        event,
        doneCallback,
        setPlayer,
      );
    }
  }, [player, opposingTeam, home, insertEvent, doneCallback, event, saving]);

  if (player === undefined || opposingTeam === undefined) {
    return <PlayerSelect setPlayer={setGamePlayer} {...props} />;
  }
  return (
    <>
      {opposingTeam.members.length > 0 ? (
        <>
          <input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            ref={(input) => {
              setInputRef(input ?? undefined);
            }}
          />

          <PlayerSelectOneTeam
            setPlayer={(
              setPlayerOpposingTeam: GameTeamI,
              gamePlayer: PlayerI,
            ) => {
              causedBySelected(
                setPlayerOpposingTeam,
                gamePlayer,
                player,
                setPlayerOpposingTeam === home ? 'home' : 'away',
                insertEvent,
                event,
                doneCallback,
                setPlayer,
              );
            }}
            team={opposingTeam}
            overrideHeader={
              <div className="action-header">
                {player.number} - {player.name} {causedByLabel} ...
              </div>
            }
            search={search}
            {...props}
          />
        </>
      ) : (
        <>Saving</>
      )}
    </>
  );
}
