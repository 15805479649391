import React from 'react';
import { Link } from 'react-router-dom';

export function Logo(): React.JSX.Element {
  return (
    <div className="logo">
      <Link to="/">
        <div className="logo-image">
          <img src="/logo.svg" height="16" alt="the logo" />
        </div>
        <div className="logo-text">VeeBall</div>
      </Link>
    </div>
  );
}
