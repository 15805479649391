import { v4 as uuidv4 } from 'uuid';
import React, {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useState,
  type PropsWithChildren,
  type ReactElement,
} from 'react';
import { type Event } from '../event/event';
import { useGame } from '../state';
import { useGameClock100MS } from '../game/gameclock';
import { type User } from '../models';
import { type ActionComponentBaseProps } from './action-component';
import { Block } from './actions/block';
import { PersonalFoul } from './actions/foul';
import { GeneralTurnover } from './actions/general-turnover';
import { TwoPointMiss, ThreePointMiss, FreeThrowMiss } from './actions/miss';
import { DefensiveRebound, OffensiveRebound } from './actions/rebound';
import { TwoPointMake, ThreePointMake, FreeThrowMake } from './actions/score';
import { Steal } from './actions/steal';
import './stat-controls.css';

interface HideIfOtherSelectedProps {
  currentEventID: string;
  children?: React.ReactNode;
}
function HideIfOtherSelected({
  children,
  currentEventID,
}: HideIfOtherSelectedProps): React.JSX.Element {
  const [active, setActive] = useState<number>();

  const childrenWithProps = Children.map(children, (child, i) => {
    const item = child as ReactElement<
      PropsWithChildren<ActionComponentBaseProps>
    >;
    if (isValidElement(item)) {
      if (typeof item.props.activeCallback !== 'function') {
        throw new Error(
          `Children of HideIfOtherSelect required activeCallback prop`,
        );
      }
      if (typeof item.props.doneCallback !== 'function') {
        throw new Error(
          `Children of HideIfOtherSelect required doneCallback prop`,
        );
      }
      if (active !== undefined && active !== i) return <div />;
      return cloneElement(item, {
        key: `${currentEventID}-${item.key ?? ''}`,
        activeCallback: () => {
          // eslint-disable-next-line no-console -- debug
          console.log(`setActive ${i.toString()}`);
          item.props.activeCallback();
          setActive(i);
        },
        doneCallback: () => {
          item.props.doneCallback();
          // eslint-disable-next-line no-console -- debug
          console.log(`setActive ${i.toString()} -> undefined`);
          setActive(undefined);
        },
      });
    }
    return item;
  });

  return <>{childrenWithProps}</>;
}

interface StatControlsProps {
  user: User;
  gameID: string;
  disable: boolean;
  statBegunCallback: () => void;
  statDoneCallback: () => void;
  shortcutEnabled: boolean;
  setShortcutEnabled: (shortcutEnable: boolean) => void;
}

export function StatControls({
  user,
  gameID,
  statBegunCallback,
  statDoneCallback,
  shortcutEnabled,
  setShortcutEnabled,
}: StatControlsProps): React.JSX.Element {
  const gameService = useGame(user, gameID);
  const gameClock100MS = useGameClock100MS();
  const [currentEventID, setCurrentEventID] = useState<string>(uuidv4());

  const doneCallback = useCallback(() => {
    setCurrentEventID(uuidv4());
    statDoneCallback();
  }, [setCurrentEventID, statDoneCallback]);

  const activeCallback = useCallback(() => {
    statBegunCallback();
  }, [statBegunCallback]);

  const gameClock100MSOrZero = gameClock100MS ?? 0;
  const gamePayload =
    gameService.status === 'loaded' ? gameService.payload : null;
  const insertEvent = useCallback(
    (event: Event) => {
      if (gamePayload === null) return Promise.resolve();
      return gamePayload.addEvent(gameID, {
        when: gameClock100MSOrZero,
        ...event,
      });
    },
    [gamePayload, gameClock100MSOrZero, gameID],
  );

  if (gameService.status === 'error') {
    return <>ARG error ${gameService.error}</>;
  }
  if (gameService.status === 'init') {
    return <>Loading</>;
  }
  if (gameService.status === 'loading') {
    return <>Loading</>;
  }
  if (gameService.status === 'not-found') {
    return <>Not found?</>;
  }

  const commonActionProps: ActionComponentBaseProps = {
    doneCallback,
    // Fake, will be replaced in HideIfOtherSelected
    activeCallback,
    insertEvent,
    when: gameClock100MSOrZero,
    home: gameService.payload.homeTeam,
    away: gameService.payload.awayTeam,
    currentEventID,
    gameID,
    shortcutEnabled,
    setShortcutEnabled,
  };

  return (
    <div className="stat-actions">
      <div className="header">
        <h3>Insert Stat</h3>
      </div>
      <div className="actions clearfix">
        <HideIfOtherSelected currentEventID={currentEventID}>
          <TwoPointMake {...commonActionProps} />
          <TwoPointMiss {...commonActionProps} />
          <ThreePointMake {...commonActionProps} />
          <ThreePointMiss {...commonActionProps} />
          <FreeThrowMake {...commonActionProps} />
          <FreeThrowMiss {...commonActionProps} />
          <DefensiveRebound {...commonActionProps} />
          <OffensiveRebound {...commonActionProps} />
          <Block {...commonActionProps} />
          <PersonalFoul {...commonActionProps} />
          <Steal {...commonActionProps} />
          <GeneralTurnover {...commonActionProps} />
        </HideIfOtherSelected>
      </div>
    </div>
  );
}
