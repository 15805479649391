import { z } from 'zod';

export const ClockEventSchema = z.object({
  cat: z.literal('ClockEvent'),
});
export type ClockEvent = z.infer<typeof ClockEventSchema>;

export const ClockStartSchema = ClockEventSchema.extend({
  what: z.literal('ClockStart'),
});
export type ClockStart = z.infer<typeof ClockStartSchema>;

export const ClockStopSchema = ClockEventSchema.extend({
  what: z.literal('ClockStop'),
});
export type ClockStop = z.infer<typeof ClockStopSchema>;

export const ClockEventsSchema = z.union([ClockStartSchema, ClockStopSchema]);
export type ClockEvents = z.infer<typeof ClockEventsSchema>;
