import React from 'react';

export function HalfWidth({
  children,
}: {
  children?: React.ReactNode;
}): React.JSX.Element {
  return <div className="halfwidth">{children}</div>;
}

export function ThirdWidth({
  children,
}: {
  children?: React.ReactNode;
}): React.JSX.Element {
  return <div className="thirdwidth">{children}</div>;
}

export function Component({
  label,
  children,
  classNames,
}: {
  label?: string;
  classNames?: string;
  children?: React.ReactNode;
}): React.JSX.Element {
  return (
    <div className={classNames ? ` ${classNames}` : ''}>
      {label !== undefined && <div className="component-header">{label}</div>}
      <div className="component-content">{children}</div>
    </div>
  );
}
