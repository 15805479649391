import React from 'react';
import { type TeamI } from '../models';
import './teamselect.css';

export interface TeamSelectOptionsProps {
  teams: TeamI[];
  sort?: boolean;
  ignoreTeams?: TeamI[];
  title: string;
  setTeam: (_team: TeamI) => void;
}

export function TeamSelectOptions({
  teams,
  title,
  setTeam,
  sort,
}: TeamSelectOptionsProps): React.JSX.Element {
  const sortedTeams = sort
    ? teams.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
        }),
      )
    : teams;
  if (!teams.length) return <div />;
  return (
    <div className="teamselect">
      <button type="button">{title}</button>
      <div className="teamselect-outer">
        <div className="teamselect-content">
          {sortedTeams.map((team) => (
            <button
              type="button"
              key={team.id}
              onClick={() => {
                setTeam(team);
              }}
            >
              {team.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
