import { z } from 'zod';
import { SideSchema } from '../models';

export const ReboundEventSchema = z.object({
  cat: z.literal('ReboundEvent'),
  side: SideSchema,
  player: z.string(),
});
export type ReboundEvent = z.infer<typeof ReboundEventSchema>;

export const DefensiveReboundSchema = ReboundEventSchema.extend({
  what: z.literal('DefensiveRebound'),
});
export type DefensiveRebound = z.infer<typeof DefensiveReboundSchema>;

export const OffensiveReboundSchema = ReboundEventSchema.extend({
  what: z.literal('OffensiveRebound'),
});
export type OffensiveRebound = z.infer<typeof OffensiveReboundSchema>;

export const ReboundEventsSchema = z.union([
  DefensiveReboundSchema,
  OffensiveReboundSchema,
]);
export type ReboundEvents = z.infer<typeof ReboundEventsSchema>;
