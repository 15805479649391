import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { VideoV2, type Player as VideoPlayer } from '../player';
import { type User, type GameTeamI, type NonIDGameTeamI } from '../models';
import { ScoreBoardFromEvents } from '../stories/scoreboard';
import { StatControls } from '../stories/stat-controls';
import { useGame, useGameEvents } from '../state';
import { SetupGameTeamPlayers, SaveOrUpdateTeam } from './game-setup';
import { BoxScore } from './box-score';
import { EventViewer } from './event-viewer';
import { updateClockHandler } from './gameclock';

//interface GameMatch {
//  gameId: string;
//}

interface GameByIdProps {
  user: User;
}

export function GameById({ user }: GameByIdProps): React.JSX.Element | null {
  const { gameId } = useParams();

  if (gameId === undefined)
    return <div className="error">No gameID specified</div>;
  return <Game user={user} gameID={gameId} shared={false} />;
}

function SaveTeam({
  setter,
  team,
}: {
  team: NonIDGameTeamI;
  setter: (team: GameTeamI) => void;
}): React.JSX.Element {
  const save = SaveOrUpdateTeam(team);
  if (save.status === 'error') {
    return <div>Error updating team: {save.error.message}</div>;
  }
  if (save.status === 'init' || save.status === 'loading') {
    return <div>Saving...</div>;
  }
  setter(save.payload);
  return <div>Saved</div>;
}

function EditTeam({
  team,
  setter,
}: {
  team: GameTeamI;
  setter: (team: GameTeamI) => void;
}): React.JSX.Element {
  const [save, setSave] = useState(false);
  const [gameTeam, setGameTeam] = useState<NonIDGameTeamI>(team);
  useEffect(() => {
    setSave(false);
    setGameTeam(team);
  }, [team]);

  if (save) {
    return (
      <SaveTeam
        team={gameTeam}
        setter={(savedTeam) => {
          setter(savedTeam);
          setSave(false);
        }}
      />
    );
  }
  return (
    <div style={{ color: 'black' }}>
      <div>{team.name}</div>
      <SetupGameTeamPlayers
        setPlayers={(players) => {
          setGameTeam({
            ...team,
            members: players,
          });
        }}
        players={gameTeam.members}
      />
      <div>
        {gameTeam.members.map((p) => (
          <div key={p.id}>{p.name}</div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => {
          setSave(true);
        }}
      >
        Save
      </button>
    </div>
  );
}

interface GameProps {
  gameID: string;
  shared: boolean;
  user: User;
}

export function Game({ gameID, user }: GameProps): React.JSX.Element {
  const [inClipReplay, setInClipReplay] = useState<{
    where: number;
    timings: [number, number][];
  }>();
  const [position, setPosition] = useState<number>(0);
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer | undefined>(
    undefined,
  );
  (
    window as Window &
      typeof globalThis & { videoPlayer: VideoPlayer | undefined }
  ).videoPlayer = videoPlayer;
  const [editTeam, setEditTeam] =
    useState<[GameTeamI, React.Dispatch<React.SetStateAction<GameTeamI>>]>();
  const [shortcutEnabled, setShortcutEnabled] = useState<boolean>(true);

  const hasVideoPlayer = videoPlayer === undefined;
  const posSeconds = position / 1000;

  const keyUpListener = useCallback(() => {
    return (evt: KeyboardEvent): void => {
      if ((inClipReplay ?? videoPlayer === undefined) || !shortcutEnabled) {
        return;
      }
      const keys = {
        spaceBar: 'Space',
        leftArrow: 'ArrowLeft',
        upArrow: 'ArrowUp',
        rightArrow: 'ArrowRight',
        downArrow: 'ArrowDown',
        m: 'KeyM',
      };
      let keyHandled = true;
      switch (evt.code) {
        case keys.leftArrow:
          videoPlayer.rewind(5);
          break;
        case keys.downArrow:
          videoPlayer.rewind(60);
          break;
        case keys.rightArrow:
          videoPlayer.forward(5);
          break;
        case keys.upArrow:
          videoPlayer.forward(60);
          break;
        case keys.spaceBar:
          switch (videoPlayer.getPlayerState()) {
            // playing
            case 1:
              videoPlayer.pauseVideo();
              break;
            // paused
            case 2:
              videoPlayer.playVideo();
              break;
          }
          break;
        case keys.m:
          videoPlayer.isMuted() ? videoPlayer.unMute() : videoPlayer.mute();
          break;
        default:
          keyHandled = false;
      }
      if (keyHandled) {
        evt.preventDefault();
      }
    };
  }, [inClipReplay, shortcutEnabled, videoPlayer]);

  useEffect(() => {
    if (inClipReplay === undefined) return;
    if (videoPlayer === undefined) return;
    const where = inClipReplay.where;

    // eslint-disable-next-line no-console -- clips debug
    console.log(
      `position=${posSeconds.toString()} inClipReplay=${JSON.stringify(inClipReplay)}`,
    );
    // Prestart
    if (inClipReplay.where === -1) {
      videoPlayer.seekTo(inClipReplay.timings[0][0], true);
      videoPlayer.playVideo();
      const timer = setInterval(() => {
        if (
          videoPlayer.getCurrentTime() > inClipReplay.timings[0][0] &&
          videoPlayer.getCurrentTime() < inClipReplay.timings[0][1]
        ) {
          clearInterval(timer);
          setInClipReplay({
            ...inClipReplay,
            where: 0,
          });
        }
      }, 100);
      return;
    }
    if (posSeconds > inClipReplay.timings[where][1]) {
      if (where + 1 === inClipReplay.timings.length) {
        videoPlayer.pauseVideo();
        setInClipReplay(undefined);
        return;
      }
      setInClipReplay({ ...inClipReplay, where: where + 1 });
      videoPlayer.seekTo(inClipReplay.timings[where + 1][0], true);
    }
  }, [hasVideoPlayer, posSeconds, inClipReplay, videoPlayer]);

  // useEffect(() => {
  //   if (videoPlayer === undefined) return;
  //   setTimeout(() => videoPlayer.pauseVideo(), 800);
  // }, [videoPlayer]);
  useEffect(() => {
    const listener = keyUpListener();
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [keyUpListener, shortcutEnabled]);

  useEffect(() => {
    if (!videoPlayer) return;
    const interval = setInterval(() => {
      const currentPosition = videoPlayer.getCurrentTime();
      setPosition(Math.round(currentPosition * 1000));
      updateClockHandler(Math.floor(currentPosition * 1000));
    }, 250);
    return () => {
      clearInterval(interval);
    };
  }, [videoPlayer]);
  // This should never happen, parent should take care
  // if (!login.loggedIn) {
  //   return <></>;
  // }
  const gameService = useGame(user, gameID);
  const gameEventsService = useGameEvents(gameID);

  if (gameEventsService.status === 'error') {
    // eslint-disable-next-line no-console -- error
    console.error(gameEventsService.error);
    return <div>Gamevents error {gameEventsService.error.message}</div>;
  }
  if (gameService.status === 'loading' || gameService.status === 'init') {
    return <div>Loading</div>;
  }
  if (gameService.status === 'not-found') {
    return <div>Game not found</div>;
  }
  if (gameService.status === 'error') {
    return <div>Error loading game: {gameService.error.message}</div>;
  }
  const game = gameService.payload;
  const canEdit =
    (game.editors ?? []).includes(user.id) || game.owner === user.id;

  // const start = useMemo(
  //   () =>
  //     !shared
  //       ? fetchEvents.reduce(
  //           (newestEvent: SavedEvent | undefined, currentValue) => {
  //             if (
  //               newestEvent === undefined ||
  //               currentValue.when > newestEvent.when
  //             ) {
  //               return currentValue;
  //             }
  //             return newestEvent;
  //           },
  //           undefined,
  //         )
  //       : undefined,
  //   [gameEventsService.payload, shared],
  // );

  return (
    <div className="game">
      <div className="container clearfix">
        <div className="video-player">
          <VideoV2
            // start={
            //   start === undefined ? undefined : Math.ceil(start.when / 1000)
            // }
            playerControl={(player) => {
              setVideoPlayer(player);
            }}
            videoId={game.videoId}
            // eslint-disable-next-line no-console -- log alot of clock
            setCurrentTime={console.log}
          />
        </div>
        {editTeam ? (
          <EditTeam
            team={editTeam[0]}
            setter={(team) => {
              setEditTeam(undefined);
              editTeam[1](team);
            }}
          />
        ) : (
          <div className="control">
            <ScoreBoardFromEvents
              gameID={gameID}
              positionSeconds={posSeconds}
            />
            {canEdit ? (
              <StatControls
                user={user}
                gameID={gameID}
                statBegunCallback={() => videoPlayer?.pauseVideo()}
                statDoneCallback={() => videoPlayer?.playVideo()}
                disable={false}
                shortcutEnabled={shortcutEnabled}
                setShortcutEnabled={setShortcutEnabled}
              />
            ) : null}
            <EventViewer
              gameID={gameID}
              positionSeconds={posSeconds}
              setInClipReplay={setInClipReplay}
              seekTo={(when: number) => videoPlayer?.seekTo(when, true)}
              homeTeam={game.homeTeam}
              awayTeam={game.awayTeam}
              setShortcutEnabled={setShortcutEnabled}
            />
          </div>
        )}
      </div>
      <BoxScore
        gameID={gameID}
        posSeconds={posSeconds}
        homeTeam={game.homeTeam}
        awayTeam={game.awayTeam}
      />{' '}
    </div>
  );
}
