import React from 'react';
import { type TimedEvent } from '../event/models';
import { type Event } from '../event/event';
import { type ScoreEvents } from '../event/score-event';
import './scoreboard.css';
import { useGameEvents } from '../state';

export interface ScoreboardTeamProps {
  score: number;
  fouls: number;
  bonus: boolean;
}

interface ScoreboardFromEventsProps {
  gameID: string;
  positionSeconds: number;
}

export function ScoreBoardFromEvents({
  gameID,
  positionSeconds,
}: ScoreboardFromEventsProps): React.JSX.Element {
  const gameEventsService = useGameEvents(gameID);
  if (gameEventsService.status !== 'loaded') return <div />;
  return (
    <Scoreboard
      {...scoreBoardFromEvents(
        gameEventsService.payload.filter(
          (e) => e.when < positionSeconds * 1000,
        ),
      )}
    />
  );
}

export const scoreBoardFromEvents = (
  events: (Event & TimedEvent)[],
): ScoreboardProps => {
  let homeScore = 0;
  let awayScore = 0;
  const score: ScoreEvents[] = events.flatMap((ev): ScoreEvents[] => {
    if (ev.cat === 'ScoreEvent') return [ev];
    return [];
  });
  score.forEach((scoreEvent) => {
    if (scoreEvent.side === 'home') {
      homeScore += scoreEvent.points;
    } else {
      awayScore += scoreEvent.points;
    }
  });

  return {
    awayScore,
    homeScore,
    minutes: 0,
    seconds: 0,
    quarter: 1,
  };
};

type SingleNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
const numberToHuman = (number: SingleNumber | undefined): string => {
  switch (number) {
    case undefined:
      return 'blank';
    case 0:
      return 'zero';
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    case 7:
      return 'seven';
    case 8:
      return 'eight';
    case 9:
      return 'nine';
  }
};

interface ScoreboardNumberProps {
  boardNumber: number;
  color: 'yellow' | 'green' | 'red';
  seats: 1 | 2 | 3 | 'auto';
  zeroPad: boolean;
}

function ScoreBoardNumber({
  boardNumber,
  color,
  seats,
  zeroPad,
}: ScoreboardNumberProps): React.JSX.Element {
  const numbers: (SingleNumber | undefined)[] = [];
  let currentNumber = boardNumber;
  while (currentNumber > 9) {
    numbers.unshift((currentNumber % 10) as SingleNumber);
    currentNumber = Math.floor(currentNumber / 10);
  }
  numbers.unshift((currentNumber % 10) as SingleNumber);

  if (seats !== 'auto') {
    while (numbers.length < seats) {
      numbers.unshift(zeroPad ? 0 : undefined);
    }
  }

  return (
    <div className="scoreboard-number">
      <div className={`grid${numbers.length.toString()} ${color} numberparent`}>
        {numbers.map((num, index) => {
          const className = [
            `c${index.toString()}`,
            'scorebboard',
            'number',
            color,
            numberToHuman(num),
          ];
          return <div key={num} className={className.join(' ')} />;
        })}
      </div>
    </div>
  );
}

export interface ScoreboardProps {
  minutes: number;
  seconds: number;
  homeScore: number;
  awayScore: number;
  quarter: number;
}
export function Scoreboard({
  homeScore,
  awayScore,
  quarter,
}: ScoreboardProps): React.JSX.Element {
  return (
    <div className="scoreboard">
      <div className="home-head team-name">HOME</div>
      <div className="away-head team-name">GUEST</div>
      <div className="home-score">
        <ScoreBoardNumber
          zeroPad={false}
          seats={3}
          boardNumber={homeScore}
          color="yellow"
        />
      </div>
      <div className="quarter">
        <div className="quarter-inner">
          <ScoreBoardNumber
            zeroPad={false}
            seats={1}
            boardNumber={quarter}
            color="red"
          />
        </div>
        Quarter
      </div>
      <div className="away-score">
        <ScoreBoardNumber
          zeroPad={false}
          seats={3}
          boardNumber={awayScore}
          color="yellow"
        />
      </div>
    </div>
  );
}
