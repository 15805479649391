import { z } from 'zod';
import { type Event } from './event/event';

export function convertEpochToMilliseconds({
  seconds,
  nanoseconds,
}: {
  seconds: number;
  nanoseconds: number;
}): number {
  // Convert seconds to milliseconds
  const millisecondsFromSeconds = seconds * 1000;
  // Convert nanoseconds to milliseconds
  const millisecondsFromNanoseconds = nanoseconds / 1_000_000;

  // Sum both values to get the total milliseconds
  return millisecondsFromSeconds + millisecondsFromNanoseconds;
}

export const IDSchema = z.object({
  id: z.string(),
});
export type ID = z.infer<typeof IDSchema>;

export interface NonIDPlayerI {
  id?: string;
  name: string;
  number: number;
}

export const NonIDPlayerISchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  number: z.number(),
});

export const sortByPlayerByName = (players: NonIDPlayerI[]): NonIDPlayerI[] => {
  return players.sort((left, right) => {
    const leftName = left.name.toLocaleLowerCase();
    const rightName = right.name.toLocaleLowerCase();
    if (leftName > rightName) return 1;
    if (leftName < rightName) return -1;
    return 0;
  });
};

export const PlayerISchema = NonIDPlayerISchema.extend({
  id: z.string(),
});
export type PlayerI = z.infer<typeof PlayerISchema>;

export interface TeamI {
  id: string;
  name: string;
  members: PlayerI[];
  competitions?: CompetitionI[];
}

export const TeamISchema = z.object({
  id: z.string(),
  name: z.string(),
  members: z.array(PlayerISchema),
});

export const guardedTeams = (json: unknown): TeamI[] => {
  return z.array(TeamISchema).parse(json);
};

export interface GameI {
  id: string;
  timestamp: Date;
  homeTeam: GameTeamI;
  awayTeam: GameTeamI;
  // events: Array<SavedEvent>;
  videoId: string;
  competition?: CompetitionI;
  owner?: string;
  editors?: string[];
}

export const GameTeamISchema = z.object({
  id: z.string(),
  parent: z.string().optional(),
  name: z.string(),
  members: z.array(PlayerISchema),
});

export const FirebaseTimestampSchema = z
  .object({
    seconds: z.number(),
    nanoseconds: z.number(),
  })
  .transform(({ seconds, nanoseconds }) => {
    return new Date(convertEpochToMilliseconds({ seconds, nanoseconds }));
  });

export const GameISchema = z.object({
  id: z.string(),
  timestamp: FirebaseTimestampSchema,
  homeTeam: GameTeamISchema,
  awayTeam: GameTeamISchema,
  videoId: z.string(),
});

export type NonEmptyString = string & { length: number };
export const nonEmptyString = (data: unknown): boolean =>
  typeof data === 'string' && data !== '';

export const SideSchema = z.union([z.literal('home'), z.literal('away')]);
export type Side = z.infer<typeof SideSchema>;

export interface NonIDGameI {
  timestamp: Date;
  homeTeam: GameTeamI;
  awayTeam: GameTeamI;
  videoId: string;
  competition?: CompetitionI;
  events: Event[];
}

export interface GameTeamI {
  id: string;
  parent?: string;
  name: string;
  members: PlayerI[];
}

export interface NonIDGameTeamI {
  id?: string;
  name: string;
  members: NonIDPlayerI[];
}

export interface NonIDTeamI {
  id?: string;
  name: string;
}

export interface CompetitionI {
  name: string;
  ageGroup: AgeGroupI;
  yearStart: number;
  yearEnd: number;
}

export const AgeGroupISchema = z.object({
  name: z.string(),
  maxAge: z.number(),
});

export const CompetitionISchema = z.object({
  name: z.string(),
  ageGroup: AgeGroupISchema,
  yearStart: z.number(),
  yearEnd: z.number(),
});

export interface AgeGroupI {
  name: string;
  maxAge: number;
}

export interface GoogleUser {
  id: string;
  isAnonymous: false;
}

export interface AnonymousUser {
  id: 'anonymous';
  isAnonymous: true;
}

export type User = GoogleUser | AnonymousUser;
