import { z } from 'zod';

export const QuarterEventSchema = z.object({
  cat: z.literal('QuarterEvent'),
});
export type QuarterEvent = z.infer<typeof QuarterEventSchema>;

export const QuarterStartSchema = QuarterEventSchema.extend({
  what: z.literal('QuarterStart'),
});
export type QuarterStart = z.infer<typeof QuarterStartSchema>;

export const QuarterEndSchema = QuarterEventSchema.extend({
  what: z.literal('QuarterEnd'),
});
export type QuarterEnd = z.infer<typeof QuarterEndSchema>;

export const QuarterEventsSchema = z.union([
  QuarterStartSchema,
  QuarterEndSchema,
]);
export type QuarterEvents = z.infer<typeof QuarterEventsSchema>;
