export const humanTime = (inputSeconds: number): string => {
  const totalSeconds = Math.floor(inputSeconds);
  const hours = Math.floor(totalSeconds / 60 / 60);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;
  if (hours > 0) {
    return [
      hours,
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');
  }
  return [minutes, seconds.toString().padStart(2, '0')].join(':');
};
