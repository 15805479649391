import React from 'react';
import {
  ActionComponent,
  type ActionComponentBaseProps,
} from '../action-component';
import { PlayerCausedBy } from '../player-with-caused-by';

export function Steal(props: ActionComponentBaseProps): React.JSX.Element {
  return (
    <ActionComponent {...props} label="🤏 Steal" shortcut={{ key: 's' }}>
      <PlayerCausedBy
        label="Steal"
        shortcut={{ key: 's' }}
        event={(side, gamePlayer, causedBy) => {
          return {
            cat: 'TurnoverEvent',
            what: 'Steal',
            side,
            player: gamePlayer || '0',
            causedBy,
          };
        }}
        causedByLabel="stole from"
        {...props}
      />
    </ActionComponent>
  );
}
