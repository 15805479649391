import YouTube from 'react-youtube';
import React from 'react';

export interface VideoProps {
  videoId: string;
  start?: number;
  playerControl: (player: Player) => void;
  setCurrentTime: (seconds: number) => void;
}
// interface VideoState {
//   player: Player | undefined;
//   intervalId: NodeJS.Timeout | undefined;
//   currentTime: number;
//   playing: boolean;
// }

export interface Player {
  getDuration: () => number;
  playVideo: () => void;
  pauseVideo: () => void;
  seekTo: (seconds: number, allowSeekAhead: boolean) => void;
  getPlayerState: () => number;
  isMuted: () => boolean;
  mute: () => void;
  unMute: () => void;
  rewind: (seconds: number) => void;
  forward: (seconds: number) => void;
  getCurrentTime: () => number;
}

export function assertPlayer(
  maybePlayer: unknown,
): asserts maybePlayer is Player {
  const requiredMethods: Record<string, number> = {
    getDuration: 0,
    playVideo: 0,
    pauseVideo: 0,
    seekTo: 2,
    getPlayerState: 0,
    isMuted: 0,
    mute: 0,
    unMute: 0,
    getCurrentTime: 0,
  };

  if (typeof maybePlayer !== 'object' || maybePlayer === null) {
    throw new Error(`Expected non-null object`);
  }
  const player = maybePlayer as Record<string, unknown>;
  for (const methodName in requiredMethods) {
    if (!(methodName in player)) {
      throw new Error(`Method '${methodName}' does not exist on player`);
    }
    if (typeof player[methodName] !== 'function') {
      throw new Error(
        `Expected method '${methodName}' to be of type 'function' but got '${typeof player[methodName]}'`,
      );
    }
  }
}

export function VideoV2(props: VideoProps): React.JSX.Element {
  return (
    <YouTube
      videoId={props.videoId}
      opts={{
        width: '100%',
        height: '1900px',
        playerVars: {
          autoplay: 1,
          controls: 1,
          start: props.start,
          mute: 1,
        },
      }}
      onReady={(event) => {
        const target: unknown = event.target;
        assertPlayer(target);
        target.rewind = (seconds: number) => {
          target.seekTo(target.getCurrentTime() - seconds, true);
        };
        target.forward = (seconds: number) => {
          target.seekTo(target.getCurrentTime() + seconds, true);
        };
        props.playerControl(target);
      }}
    />
  );
}

interface PlayPauseProps {
  playing: boolean;
  pauseCommand: () => void;
  playCommand: () => void;
}

export function PlayPause({
  playing,
  pauseCommand,
  playCommand,
}: PlayPauseProps): React.JSX.Element {
  if (playing) {
    return (
      <button type="button" onClick={pauseCommand}>
        Pause
      </button>
    );
  }
  return (
    <button type="button" onClick={playCommand}>
      Play
    </button>
  );
}
