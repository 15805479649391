import React, { Children, type ReactNode, useState } from 'react';
import { useSize } from './use-size';
import './Tile.css';

// interface TileShape {
//   cols: number;
//   innerWidth: number;
//   rowHeight: number;
// }

// type Child =
//   | string
//   | number
//   | boolean
//   | {}
//   | React.ReactElement
//   | React.ReactNodeArray
//   | React.ReactPortal
//   | null
//   | undefined;

interface TileProps {
  children: React.ReactNode;
}
export function Tile({ children }: TileProps): React.JSX.Element {
  const [container, setContainer] = useState<HTMLDivElement>();

  const windowSize = useSize(container ?? undefined);

  const cols = Math.floor(windowSize.width / 470) + 1;
  const rowHeight = Math.floor((windowSize.width / cols) * 0.5);

  const rows: ReactNode[][] = [[]];
  // const rows: Child[][] = [[]];
  Children.map(children, (child, currentIndex) => {
    const lastRow = rows[rows.length - 1];
    if (currentIndex % cols || currentIndex === 0) {
      lastRow.push(child);
    } else {
      rows.push([child]);
    }
  });

  const widthPercentage = Math.floor(100 / cols);

  return (
    <div
      data-testid="tile-container"
      ref={(e) => {
        setContainer(e ?? undefined);
      }}
      className="tile"
    >
      {rows.map((row, i) => (
        <div
          key={`row-${i.toString()}`}
          className="row clearfix"
          data-testid={`row-${i.toString()}`}
          style={{ height: `${rowHeight.toString()}px` }}
        >
          {row.map((col, x) => (
            <div
              key={`row-${i.toString()}-col-${x.toString()}`}
              data-testid={`row-${(i + 1).toString()}-col-${(x + 1).toString()}`}
              style={{ width: `${widthPercentage.toString()}%` }}
              className="col"
            >
              {col}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
