import React, { Component, createRef, type RefObject } from 'react';
import { Link } from 'react-router-dom';
import { formatDistanceStrict } from 'date-fns';
import { type GoogleUser, type GameI } from '../models';
import { useGames } from '../state';

export interface GameThumbnailProps {
  homeTeam: string;
  awayTeam: string;
  videoId: string;
  gameId: string;
  date: Date;
  competitionName: string;
}

class GameThumbnail extends Component<GameThumbnailProps> {
  render(): React.JSX.Element {
    const backgroundCss = {
      background: `rgba(0, 0, 0, 255) url('https://img.youtube.com/vi/${this.props.videoId}/0.jpg') center no-repeat`,
      width: '100%',
      height: '100%',
    };
    return (
      <div className="game-thumbnail-overview">
        <Link to={`/game/${this.props.gameId}`}>
          <div className="video-thumbnail" style={backgroundCss}>
            <div className="game-overview-title">
              <div className="bong">
                {this.props.homeTeam} vs {this.props.awayTeam}
              </div>
              <div className="game-overview-info clearfix">
                <div className="game-overview-when" style={{ float: 'left' }}>
                  {formatDistanceStrict(this.props.date, new Date(), {
                    addSuffix: true,
                  })}
                </div>
                <div
                  className="game-overview-competition"
                  style={{ float: 'right' }}
                >
                  {this.props.competitionName}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export function GameList({ user }: { user: GoogleUser }): React.JSX.Element {
  const gamesService = useGames(user);
  if (gamesService.status === 'error') {
    // eslint-disable-next-line no-console -- this is weird, log it
    console.error(gamesService.error);
    return <div>Error {gamesService.error.message}</div>;
  }
  if (gamesService.status === 'loaded') {
    const orderedGames = gamesService.payload.sort((a, b) => {
      return b.timestamp.getTime() - a.timestamp.getTime();
    });
    // eslint-disable-next-line no-console -- debug
    console.log(orderedGames.map((g) => g.timestamp.toString()).join('\n'));
    return <GameListPure games={orderedGames} />;
  }
  return <div>Loading</div>;
}

export interface GameListPureProps {
  games: GameI[];
}

export interface GameListPureState {
  cols: number;
  innerWidth: number;
  rowHeight: number;
  filter?: string;
}

export class GameListPure extends Component<
  GameListPureProps,
  GameListPureState
> {
  containerRef: RefObject<HTMLDivElement>;
  games: GameI[];

  constructor(props: GameListPureProps) {
    super(props);
    this.containerRef = createRef();
    this.games = props.games;

    this.state = {
      cols: 1,
      innerWidth: 0,
      rowHeight: 0,
      filter: undefined,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = (): void => {
    if (this.containerRef.current) {
      const totalWidth = this.containerRef.current.clientWidth;
      // 480 is the actual thumbnail size
      const cols = Math.floor(totalWidth / 470) + 1;
      const rowHeight = Math.floor((totalWidth / cols) * 0.5);
      const stateUpdate = {
        cols,
        innerWidth: totalWidth,
        rowHeight,
      };
      // eslint-disable-next-line no-console -- debug
      console.log(stateUpdate);
      this.setState(stateUpdate);
    }
  };

  componentDidMount = (): void => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line no-console -- debug
    console.log(event.currentTarget.value);
    this.setState({ filter: event.target.value });
  };

  render(): React.JSX.Element {
    const lowerCaseFilter = (this.state.filter ?? '').toLocaleLowerCase();
    const startValue: GameI[][] = [[]];
    const rows = this.games
      .filter((game) => {
        return (
          !this.state.filter ||
          game.homeTeam.name.toLocaleLowerCase().includes(lowerCaseFilter) ||
          game.awayTeam.name.toLocaleLowerCase().includes(lowerCaseFilter)
        );
      })
      .reduce((acc, currentValue, currentIndex) => {
        const lastRow = acc[acc.length - 1];
        if (currentIndex % this.state.cols || currentIndex === 0) {
          lastRow.push(currentValue);
        } else {
          acc.push([currentValue]);
        }
        return acc;
      }, startValue);
    return (
      <div className="game-list clearfix" ref={this.containerRef}>
        <div className="search">
          <form>
            <label>
              Search{' '}
              <input
                type="text"
                name="search"
                onChange={this.handleSearchChange}
              />
            </label>
          </form>
        </div>
        {rows.map((games) => {
          const widthPercentage = 99 / this.state.cols;
          return (
            <div
              key={JSON.stringify(games)}
              className="game list-row clearfix"
              style={{ height: `${this.state.rowHeight.toString()}px` }}
            >
              {games.map((game) => {
                return (
                  <div
                    key={game.id}
                    className="game-list-cell"
                    style={{ width: `${widthPercentage.toString()}%` }}
                  >
                    <GameThumbnail
                      key={game.id}
                      gameId={game.id}
                      homeTeam={game.homeTeam.name}
                      awayTeam={game.awayTeam.name}
                      videoId={game.videoId}
                      competitionName={
                        game.competition !== undefined
                          ? `${game.competition.name} ${game.competition.ageGroup.name}`
                          : ''
                      }
                      date={game.timestamp}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}
