import React, { useState } from 'react';
import { type Event } from '../event/event';
import { type GameTeamI, type Side } from '../models';
import './boxscore.css';
import { useGameEvents } from '../state';

// interface BoxScoreI {
//   homeTeam: {
//     player: Record<string, Stats>;
//     total: Stats;
//   };
//   awayTeam: {
//     player: Record<string, Stats>;
//     total: Stats;
//   };
// }

interface Stats {
  freeThrows: {
    make: number;
    miss: number;
  };
  twoPoints: {
    make: number;
    miss: number;
  };
  threePoints: {
    make: number;
    miss: number;
  };
  assists: number;
  blocks: number;
  blocksAgainst: number;
  steals: number;
  personalFouls: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  turnOvers: number;
}

const emptyStats: () => Stats = () => {
  return {
    freeThrows: {
      make: 0,
      miss: 0,
    },
    twoPoints: {
      make: 0,
      miss: 0,
    },
    threePoints: {
      make: 0,
      miss: 0,
    },
    assists: 0,
    blocks: 0,
    blocksAgainst: 0,
    defensiveRebounds: 0,
    offensiveRebounds: 0,
    personalFouls: 0,
    steals: 0,
    turnOvers: 0,
  };
};

const sumStats = (a: Stats, b: Stats): Stats => {
  return {
    assists: a.assists + b.assists,
    blocks: a.blocks + b.blocks,
    blocksAgainst: a.blocksAgainst + b.blocksAgainst,
    defensiveRebounds: a.defensiveRebounds + b.defensiveRebounds,
    freeThrows: {
      miss: a.freeThrows.miss + b.freeThrows.miss,
      make: a.freeThrows.make + b.freeThrows.make,
    },
    offensiveRebounds: a.offensiveRebounds + b.offensiveRebounds,
    personalFouls: a.personalFouls + b.personalFouls,
    steals: a.steals + b.steals,
    threePoints: {
      make: a.threePoints.make + b.threePoints.make,
      miss: a.threePoints.miss + b.threePoints.miss,
    },
    turnOvers: a.turnOvers + b.turnOvers,
    twoPoints: {
      make: a.twoPoints.make + b.twoPoints.make,
      miss: a.twoPoints.miss + b.twoPoints.miss,
    },
  };
};

const updateStat = (
  playerOrTeam: PlayerOrTeam,
  event: Event,
  stat: Stats,
): Stats => {
  const newStat = { ...stat };
  switch (event.what) {
    case 'ClockStart':
      return newStat;
    case 'ClockStop':
      return newStat;
    case 'DefensiveRebound':
      newStat.defensiveRebounds++;
      return newStat;
    case 'OffensiveRebound':
      newStat.offensiveRebounds++;
      return newStat;
    case 'FreeThrowMake':
      newStat.freeThrows.make++;
      return newStat;
    case 'FreeThrowMiss':
      newStat.freeThrows.miss++;
      return newStat;
    case 'TwoPointMake':
      if (
        playerOrTeam.type === 'player' &&
        event.assistedBy === playerOrTeam.player
      ) {
        newStat.assists++;
      } else {
        newStat.twoPoints.make++;
      }
      return newStat;
    case 'TwoPointMiss':
      newStat.twoPoints.miss++;
      return newStat;
    case 'ThreePointMake':
      if (
        playerOrTeam.type === 'player' &&
        event.assistedBy === playerOrTeam.player
      ) {
        newStat.assists++;
      } else {
        newStat.threePoints.make++;
      }
      return newStat;
    case 'ThreePointMiss':
      newStat.threePoints.miss++;
      return newStat;
    case 'QuarterStart':
      return newStat;
    case 'QuarterEnd':
      return newStat;
    case 'Block':
      newStat.blocks++;
      return newStat;
    case 'Steal':
      if (
        playerOrTeam.type === 'player' &&
        event.player === playerOrTeam.player
      ) {
        newStat.turnOvers++;
      } else {
        newStat.steals++;
      }
      return newStat;
    case 'Traveling':
      newStat.turnOvers++;
      return newStat;
    case 'Ballhandling':
      newStat.turnOvers++;
      return newStat;
    case 'GeneralTurnover':
      // if (event.player === playerID) {
      newStat.turnOvers++;
      // }
      return newStat;
    case 'PersonalFoul':
      newStat.personalFouls++;
      return newStat;
  }
};

interface SideEvents {
  type: 'side';
  side: Side;
}
interface PlayerEvents {
  type: 'player';
  player: string;
}

type PlayerOrTeam = SideEvents | PlayerEvents;
type PlayerOrTeamEvents = PlayerOrTeam & {
  events: Event[];
};
const byPlayer = (events: Event[]): PlayerOrTeamEvents[] => {
  const byTeamOrPlayerEvents = events.reduce<
    Map<SideEvents | PlayerEvents, Event[]>
  >((acc: Map<SideEvents | PlayerEvents, Event[]>, currentValue) => {
    const affected: PlayerOrTeam[] = [];
    if (currentValue.cat === 'QuarterEvent') return acc;
    if (currentValue.cat === 'ClockEvent') return acc;

    if (currentValue.player === '0') {
      affected.push({
        type: 'side',
        side: currentValue.side,
      });
    } else {
      affected.push({
        type: 'player',
        player: currentValue.player,
      });
    }

    if (
      (currentValue.what === 'TwoPointMake' ||
        currentValue.what === 'ThreePointMake') &&
      currentValue.assistedBy !== undefined
    ) {
      affected.push({ type: 'player', player: currentValue.assistedBy });
    }
    if (
      currentValue.cat === 'TurnoverEvent' &&
      currentValue.causedBy !== undefined
    ) {
      affected.push({ type: 'player', player: currentValue.causedBy });
    }
    affected.forEach((currentPlayerOrSide) => {
      const currentPlayerEvents = acc.get(currentPlayerOrSide) ?? [];
      const playerEvents = [...currentPlayerEvents, currentValue];
      acc.set(currentPlayerOrSide, playerEvents);
    });
    return acc;
  }, new Map());
  return Array.from(byTeamOrPlayerEvents.entries()).map(
    ([playerOrTeam, evs]) => {
      return {
        ...playerOrTeam,
        events: evs,
      };
    },
  );
};

const playerEventsByTeam = (
  team: GameTeamI,
  side: Side,
  events: PlayerOrTeamEvents[],
): PlayerOrTeamEvents[] => {
  const playerIds = team.members.map((member) => member.id);
  return events.filter((event) => {
    if (event.type === 'side') {
      return event.side === side;
    }
    return playerIds.includes(event.player);
  });
};

interface BoxScoreProps {
  gameID: string;
  homeTeam: GameTeamI;
  awayTeam: GameTeamI;
  posSeconds: number;
}
export function BoxScore({
  homeTeam,
  awayTeam,
  posSeconds,
  gameID,
}: BoxScoreProps): React.JSX.Element | null {
  const [fullGame, setFullGame] = useState(false);
  const eventsService = useGameEvents(gameID);

  if (eventsService.status !== 'loaded') return null;
  const rawEvents = eventsService.payload;
  const filteredEvents = fullGame
    ? rawEvents
    : rawEvents.filter((evt) => evt.when < posSeconds * 1000);

  const events = filteredEvents;

  const eventsByPlayer = byPlayer(events);

  return (
    <div className="boxscore clearfix">
      <div className="header">
        <h2>BoxScore</h2>
        <button
          type="button"
          className="link-button"
          disabled={fullGame}
          onClick={() => {
            setFullGame(true);
          }}
        >
          Full
        </button>{' '}
        -
        <button
          type="button"
          className="link-button"
          disabled={!fullGame}
          onClick={() => {
            setFullGame(false);
          }}
        >
          Current
        </button>
      </div>
      <div className="small-hundred">
        <Team
          team={homeTeam}
          playerEvents={playerEventsByTeam(homeTeam, 'home', eventsByPlayer)}
        />
      </div>
      <div className="small-hundred">
        <Team
          team={awayTeam}
          playerEvents={playerEventsByTeam(awayTeam, 'away', eventsByPlayer)}
        />
      </div>
    </div>
  );
}

const attemptPercent = (score: {
  make: number;
  miss: number;
}): number | undefined => {
  const { make, miss } = score;
  if (make + miss === 0) return undefined;
  return Math.round((make / (make + miss)) * 100);
};

type SortKey =
  | 'Name'
  | 'PTS'
  | 'AST'
  | 'REB'
  | 'STL'
  | 'BLK'
  | 'BA'
  | 'FGM'
  | 'FGA'
  | 'FG%'
  | '3PM'
  | '3PA'
  | '3P%'
  | 'FTM'
  | 'FTA'
  | 'FT%'
  | 'OREB'
  | 'DREB'
  | 'TOV'
  | 'PF'
  | 'EFF';

const fieldGoalAttempts: (stats?: Stats) => number = (stats) => {
  if (stats === undefined) return 0;
  return (
    stats.twoPoints.make +
    stats.twoPoints.miss +
    stats.threePoints.make +
    stats.threePoints.miss
  );
};

const fieldGoalMake: (stats?: Stats) => number = (stats) => {
  if (stats === undefined) return 0;
  return stats.twoPoints.make + stats.threePoints.make;
};
const fieldGoalMiss: (stats?: Stats) => number = (stats) => {
  if (stats === undefined) return 0;
  return stats.twoPoints.miss + stats.threePoints.miss;
};

const threePointAttempts: (stats?: Stats) => number = (stats) => {
  if (stats === undefined) return 0;
  return stats.threePoints.make + stats.threePoints.miss;
};
const freethrowAttempts: (stats?: Stats) => number = (stats) => {
  if (stats === undefined) return 0;
  return stats.freeThrows.make + stats.freeThrows.miss;
};

const sortedNumber: (compare: [number, number]) => number = ([a, b]) => {
  if (a < b) return 1;
  if (a === b) return 0;
  return -1;
};

const sortedString: (compare: [string, string]) => number = ([a, b]) => {
  if (a < b) return -1;
  if (a === b) return 0;
  return 1;
};

const sorted: (compare: [number, number] | [string, string]) => number = ([
  a,
  b,
]) => {
  if (typeof a === 'string' && typeof b === 'string') sortedString([a, b]);
  if (typeof a === 'number' && typeof b === 'number') sortedNumber([a, b]);
  return -1;
};

function StatHeader({
  stat,
  name,
  setSort,
  currentSort,
}: {
  currentSort: SortKey;
  stat: SortKey;
  name?: string;
  setSort: (sort: SortKey) => void;
}): React.JSX.Element {
  return (
    <th
      onClick={() => {
        setSort(stat);
      }}
      className={currentSort === stat ? 'sorting' : ''}
    >
      {name ?? stat}
    </th>
  );
}

interface TeamProps {
  team: GameTeamI;
  playerEvents: PlayerOrTeamEvents[];
}
function Team(props: TeamProps): React.JSX.Element {
  const [sort, setSort] = useState<SortKey>('Name');

  let teamStats = emptyStats();
  const playerEventRows = props.playerEvents.map((playerEvent) => {
    const playerStats = playerEvent.events.reduce((acc, currentValue) => {
      return updateStat(playerEvent, currentValue, acc);
    }, emptyStats());
    const player = props.team.members.find(
      (p) => playerEvent.type === 'player' && p.id === playerEvent.player,
    );
    teamStats = sumStats(teamStats, playerStats);
    return player !== undefined
      ? {
          player,
          stats: playerStats,
          element: (
            <PlayerStatsRow
              id={player.id}
              hideName={false}
              player={player}
              stats={playerStats}
            />
          ),
        }
      : { player, element: null };
  });

  return (
    <>
      <div className="team-name">{props.team.name}</div>
      {props.playerEvents.length === 0 ? null : (
        <table>
          <thead>
            <tr>
              {props.team.members.length > 0 && (
                <StatHeader
                  currentSort={sort}
                  setSort={setSort}
                  stat="Name"
                  name="Player"
                />
              )}
              <StatHeader currentSort={sort} setSort={setSort} stat="PTS" />
              <StatHeader currentSort={sort} setSort={setSort} stat="AST" />
              <StatHeader currentSort={sort} setSort={setSort} stat="REB" />
              <StatHeader currentSort={sort} setSort={setSort} stat="STL" />
              <StatHeader currentSort={sort} setSort={setSort} stat="BLK" />
              <StatHeader currentSort={sort} setSort={setSort} stat="BA" />
              <StatHeader currentSort={sort} setSort={setSort} stat="FGM" />
              <StatHeader currentSort={sort} setSort={setSort} stat="FGA" />
              <StatHeader currentSort={sort} setSort={setSort} stat="FG%" />
              <StatHeader currentSort={sort} setSort={setSort} stat="3PM" />
              <StatHeader currentSort={sort} setSort={setSort} stat="3PA" />
              <StatHeader currentSort={sort} setSort={setSort} stat="3P%" />
              <StatHeader currentSort={sort} setSort={setSort} stat="FTM" />
              <StatHeader currentSort={sort} setSort={setSort} stat="FTA" />
              <StatHeader currentSort={sort} setSort={setSort} stat="FT%" />
              <StatHeader currentSort={sort} setSort={setSort} stat="OREB" />
              <StatHeader currentSort={sort} setSort={setSort} stat="DREB" />
              <StatHeader currentSort={sort} setSort={setSort} stat="TOV" />
              <StatHeader currentSort={sort} setSort={setSort} stat="PF" />
              <StatHeader currentSort={sort} setSort={setSort} stat="EFF" />
            </tr>
          </thead>
          <tbody>
            {playerEventRows
              .sort((a, b) => {
                let compare: [string, string] | [number, number];
                switch (sort) {
                  case 'Name':
                    compare = [a.player?.name ?? '', b.player?.name ?? ''];
                    break;
                  case 'PTS': {
                    const pointsA = a.stats !== undefined ? points(a.stats) : 0;
                    const pointsB = b.stats !== undefined ? points(b.stats) : 0;
                    compare = [pointsA, pointsB];
                    break;
                  }
                  case 'AST':
                    compare = [a.stats?.assists ?? 0, b.stats?.assists ?? 0];
                    break;
                  case 'REB': {
                    const reboundsA =
                      (a.stats?.defensiveRebounds ?? 0) +
                      (a.stats?.offensiveRebounds ?? 0);
                    const reboundsB =
                      (b.stats?.defensiveRebounds ?? 0) +
                      (b.stats?.offensiveRebounds ?? 0);
                    compare = [reboundsA, reboundsB];
                    break;
                  }
                  case 'STL':
                    compare = [a.stats?.steals ?? 0, b.stats?.steals ?? 0];
                    break;
                  case 'BLK':
                    compare = [a.stats?.blocks ?? 0, b.stats?.blocks ?? 0];
                    break;
                  case 'BA':
                    compare = [
                      a.stats?.blocksAgainst ?? 0,
                      b.stats?.blocksAgainst ?? 0,
                    ];
                    break;
                  case 'FGM':
                    compare = [fieldGoalMake(a.stats), fieldGoalMake(b.stats)];
                    break;
                  case 'FGA':
                    compare = [
                      fieldGoalAttempts(a.stats),
                      fieldGoalAttempts(b.stats),
                    ];
                    break;
                  case 'FG%':
                    compare = [
                      attemptPercent({
                        make: fieldGoalMake(a.stats),
                        miss: fieldGoalMiss(a.stats),
                      }) ?? 0,
                      attemptPercent({
                        make: fieldGoalMake(b.stats),
                        miss: fieldGoalMiss(b.stats),
                      }) ?? 0,
                    ];
                    break;
                  case '3PM':
                    compare = [
                      a.stats?.threePoints.make ?? 0,
                      b.stats?.threePoints.make ?? 0,
                    ];
                    break;
                  case '3PA':
                    compare = [
                      threePointAttempts(a.stats),
                      threePointAttempts(b.stats),
                    ];
                    break;
                  case '3P%':
                    compare = [
                      attemptPercent({
                        make: a.stats?.threePoints.make ?? 0,
                        miss: a.stats?.threePoints.miss ?? 0,
                      }) ?? 0,
                      attemptPercent({
                        make: b.stats?.threePoints.make ?? 0,
                        miss: b.stats?.threePoints.miss ?? 0,
                      }) ?? 0,
                    ];
                    break;
                  case 'FTM':
                    compare = [
                      a.stats?.freeThrows.make ?? 0,
                      b.stats?.freeThrows.make ?? 0,
                    ];
                    break;
                  case 'FTA':
                    compare = [
                      freethrowAttempts(a.stats),
                      freethrowAttempts(b.stats),
                    ];
                    break;
                  case 'FT%':
                    compare = [
                      attemptPercent({
                        make: a.stats?.freeThrows.make ?? 0,
                        miss: a.stats?.freeThrows.miss ?? 0,
                      }) ?? 0,
                      attemptPercent({
                        make: b.stats?.freeThrows.make ?? 0,
                        miss: b.stats?.freeThrows.miss ?? 0,
                      }) ?? 0,
                    ];
                    break;
                  case 'OREB':
                    compare = [
                      a.stats?.offensiveRebounds ?? 0,
                      b.stats?.offensiveRebounds ?? 0,
                    ];
                    break;
                  case 'DREB':
                    compare = [
                      a.stats?.defensiveRebounds ?? 0,
                      b.stats?.defensiveRebounds ?? 0,
                    ];
                    break;
                  case 'TOV':
                    compare = [
                      a.stats?.turnOvers ?? 0,
                      b.stats?.turnOvers ?? 0,
                    ];
                    break;
                  case 'PF':
                    compare = [
                      a.stats?.personalFouls ?? 0,
                      b.stats?.personalFouls ?? 0,
                    ];
                    break;
                  case 'EFF':
                    compare = [
                      a.stats !== undefined
                        ? efficiency(points(a.stats), a.stats)
                        : 0,
                      b.stats !== undefined
                        ? efficiency(points(b.stats), b.stats)
                        : 0,
                    ];
                    break;
                }
                return sorted(compare);
              })
              .map((item) => item.element)}
            <PlayerStatsRow
              className="team"
              hideName={!(props.team.members.length > 0)}
              id={props.team.id}
              stats={teamStats}
            />
          </tbody>
        </table>
      )}
    </>
  );
}

const points = (stats: Stats): number => {
  return (
    stats.freeThrows.make +
    stats.twoPoints.make * 2 +
    stats.threePoints.make * 3
  );
};

const efficiency = (point: number, stats: Stats): number =>
  point +
  stats.offensiveRebounds +
  stats.defensiveRebounds +
  stats.assists +
  stats.steals +
  stats.blocks -
  stats.freeThrows.miss -
  stats.twoPoints.miss -
  stats.threePoints.miss -
  stats.turnOvers;

interface PlayerStatsRowProps {
  className?: string;
  id: string;
  hideName: boolean;
  player?: {
    name: string;
    number: number;
  };
  stats: Stats;
}
function PlayerStatsRow({
  id,
  player,
  hideName,
  stats,
  className,
}: PlayerStatsRowProps): React.JSX.Element {
  const fgPercent = attemptPercent({
    make: stats.twoPoints.make + stats.threePoints.make,
    miss: stats.twoPoints.miss + stats.threePoints.miss,
  });
  const threePointPercent = attemptPercent(stats.threePoints);
  const freeThrowPercent = attemptPercent(stats.freeThrows);
  return (
    <tr key={id} className={className}>
      {!hideName && (
        <td className="player">
          {player !== undefined && (
            <>
              <div className="number">{player.number}</div>
              <div>{player.name}</div>
            </>
          )}
        </td>
      )}
      <td>{points(stats)}</td>
      <td>{stats.assists}</td>
      <td>{stats.defensiveRebounds + stats.offensiveRebounds}</td>
      <td>{stats.steals}</td>
      <td>{stats.blocks}</td>
      <td>{stats.blocksAgainst}</td>
      <td>{stats.twoPoints.make + stats.threePoints.make}</td>
      <td>
        {stats.twoPoints.make +
          stats.twoPoints.miss +
          stats.threePoints.make +
          stats.threePoints.miss}
      </td>
      <td>{fgPercent === undefined ? '' : `${fgPercent.toString()}%`}</td>
      <td>{stats.threePoints.make}</td>
      <td>{stats.threePoints.make + stats.threePoints.miss}</td>
      <td>
        {threePointPercent === undefined
          ? ''
          : `${threePointPercent.toString()}%`}
      </td>
      <td>{stats.freeThrows.make}</td>
      <td>{stats.freeThrows.make + stats.freeThrows.miss}</td>
      <td>
        {freeThrowPercent === undefined
          ? ''
          : `${freeThrowPercent.toString()}%`}
      </td>
      <td>{stats.offensiveRebounds}</td>
      <td>{stats.defensiveRebounds}</td>
      <td>{stats.turnOvers}</td>
      <td>{stats.personalFouls}</td>
      <td className="efficiency">{efficiency(points(stats), stats)}</td>
    </tr>
  );
}
