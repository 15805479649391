import React, { useCallback, useEffect, useState } from 'react';
import { HalfWidth } from '../layout';
import { type PlayerI, type GameTeamI } from '../models';
import './player.css';

export interface PlayerSelectProps {
  setPlayer: (team: GameTeamI, gamePlayer: PlayerI) => void;
  home: GameTeamI;
  away: GameTeamI;
  setShortcutEnabled: (shortcutEnabled: boolean) => void;
}

export function PlayerSelect({
  home,
  away,
  setPlayer,
  setShortcutEnabled,
}: PlayerSelectProps): React.JSX.Element {
  const [search, setSearch] = useState<string>('');
  const [inputRef, setInputRef] = useState<HTMLInputElement>();

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  useEffect(() => {
    setShortcutEnabled(false);
    return () => {
      setShortcutEnabled(true);
    };
  }, [setShortcutEnabled]);

  return (
    <>
      <input
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        ref={(input) => {
          setInputRef(input ?? undefined);
        }}
      />

      <div className="clearfix">
        <HalfWidth>
          <PlayerSelectOneTeam
            setPlayer={setPlayer}
            team={home}
            search={search}
          />
        </HalfWidth>
        <HalfWidth>
          <PlayerSelectOneTeam
            setPlayer={setPlayer}
            team={away}
            search={search}
          />
        </HalfWidth>
      </div>
    </>
  );
}

export interface PlayerSelectOneTeamProps {
  setPlayer: (team: GameTeamI, gamePlayer: PlayerI) => void;
  cancel?: {
    label: string;
    func: () => void;
  };
  team: GameTeamI;
  excludePlayerIds?: string[];
  overrideHeader?: React.ReactElement;
  search: string;
}

export const arrayEq: <T>(a: T[], b: T[]) => boolean = (a, b) => {
  if (a.length !== b.length) return false;

  return a.every((v, i) => v === b[i]);
};

const escapeRegExp = (s: string): string => {
  // $& means the whole matched string
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const searchMatch = (text: string, search: string): boolean => {
  const reText = search
    .split('')
    .map((char) => escapeRegExp(char))
    .join('.*');
  // eslint-disable-next-line no-console -- debug
  console.log(reText);
  return text.search(new RegExp(reText, 'i')) !== -1;
};

export function PlayerSelectOneTeamWithoutPlayers(): null {
  return null;
}

export function PlayerSelectOneTeam({
  setPlayer,
  cancel,
  team,
  excludePlayerIds,
  overrideHeader,
  search,
}: PlayerSelectOneTeamProps): React.JSX.Element {
  const excludedPlayers = excludePlayerIds ? excludePlayerIds : [];
  const cancelSearchMatched =
    cancel !== undefined && searchMatch(cancel.label, search);
  const nonEmptyPlayers: PlayerI[] =
    team.members.length === 0
      ? [
          {
            id: '0',
            name: team.name,
            number: 0,
          },
        ]
      : team.members;
  const players: PlayerI[] = nonEmptyPlayers.filter((player) => {
    if (excludedPlayers.includes(player.id)) return false;
    if (search !== '') {
      return searchMatch(`${player.number.toString()} ${player.name}`, search);
    }
    return true;
  });
  const enterHandler = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Enter') {
        if (players.length === 0) {
          if (cancelSearchMatched) {
            cancel.func();
          }
        } else if (players.length === 1) {
          setPlayer(team, players[0]);
        }
        ev.preventDefault();
      }
    },
    [team, players, setPlayer, cancelSearchMatched, cancel],
  );

  useEffect(() => {
    document.addEventListener('keyup', enterHandler);
    return () => {
      document.removeEventListener('keyup', enterHandler);
    };
  }, [enterHandler]);

  let showCancel = true;
  if (cancel === undefined) showCancel = false;
  else if (search !== '' && !cancelSearchMatched) showCancel = false;

  const maybeCancelButton =
    showCancel && cancel !== undefined ? (
      <button
        type="button"
        className="cancel"
        name="cancel"
        onClick={() => {
          cancel.func();
        }}
      >
        {cancel.label}
      </button>
    ) : undefined;
  return (
    <div className="player-select" data-testid="player-select">
      {overrideHeader ? (
        overrideHeader
      ) : (
        <div className="action-header">{team.name}</div>
      )}
      {maybeCancelButton}
      {players.map((player) => (
        <PlayerButton
          key={player.id}
          onClick={() => {
            setPlayer(team, player);
          }}
          player={player}
        />
      ))}
    </div>
  );
}

function PlayerButton({
  player,
  onClick,
}: {
  player: PlayerI;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}): React.JSX.Element {
  return (
    <div className="player-button" data-testid="player-button">
      <button type="button" className="player-button" onClick={onClick}>
        <div className="number">{player.number}</div>
        <div className="name">{player.name}</div>
      </button>
    </div>
  );
}
