import React from 'react';
import './style.css';

interface NoProps {
  children?: React.ReactNode;
}

export function H2({ children }: NoProps): React.JSX.Element {
  return <h2 className="vstyle">{children}</h2>;
}

export function H3({ children }: NoProps): React.JSX.Element {
  return <h3 className="vstyle">{children}</h3>;
}

export function P({ children }: NoProps): React.JSX.Element {
  return <p className="vstyle">{children}</p>;
}
