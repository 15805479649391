import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { Navigate } from 'react-router';
import { useAuth, useFirestore, useUser } from 'reactfire';
import { type Service, type ServiceSave } from '../service';
import {
  type TeamI,
  type NonIDGameTeamI,
  type GameTeamI,
  type NonIDPlayerI,
  sortByPlayerByName,
  type PlayerI,
  type GameI,
  PlayerISchema,
} from '../models';
import { TeamSelectOptions } from '../stories/team-select';
import './gamesetup.css';
import { useTeams } from '../state';
import { SteppedProgress } from '../stories/stepped-progress';
import { SelectVideo } from '../stories/select-video';

type SetupTeam = NonIDGameTeamI | GameTeamI;
type StatNewOrExistingTeamOrNotT = 'new' | 'existing' | 'not';

interface BigButtonProps {
  setter: () => void;
  children?: React.ReactNode;
}
function BigButton({ setter, children }: BigButtonProps): React.JSX.Element {
  return (
    <button
      type="button"
      onClick={() => {
        setter();
      }}
    >
      <div>{children}</div>
    </button>
  );
}
interface PickNewOrExistingTeamOrNotProps {
  disableSelect?: boolean;
  setter: (what: StatNewOrExistingTeamOrNotT) => void;
}
function PickNewOrExistingTeamOrNot({
  setter,
  disableSelect,
}: PickNewOrExistingTeamOrNotProps): React.JSX.Element {
  return (
    <div className="pick-new-or-existing-team-or-not">
      {!disableSelect && (
        <BigButton
          setter={() => {
            setter('existing');
          }}
        >
          <span style={{ textAlign: 'center' }}>Select</span>
          <div className="small-text">Select an existing team</div>
        </BigButton>
      )}
      <BigButton
        setter={() => {
          setter('new');
        }}
      >
        <span style={{ textAlign: 'center' }}>Add</span>
        <div className="small-text">Add a new team</div>
      </BigButton>
      <BigButton
        setter={() => {
          setter('not');
        }}
      >
        <span style={{ textAlign: 'center' }}>Skip</span>
        <div className="small-text">I do not want to stat this side</div>
      </BigButton>
    </div>
  );
}

interface SetupNameOnlyTeamProps {
  setter: (team: NonIDGameTeamI) => void;
}
function SetupNameOnlyTeam({
  setter,
}: SetupNameOnlyTeamProps): React.JSX.Element {
  const [teamName, setTeamName] = useState('');

  const setTeam = (): void => {
    setter({
      name: teamName,
      members: [],
    });
  };

  return (
    <input
      ref={(element) => element?.focus()}
      value={teamName}
      onKeyUp={(event) => {
        event.key === 'Enter' && setTeam();
      }}
      onChange={(event) => {
        setTeamName(event.target.value);
      }}
      placeholder="Team Name"
    />
  );
}

interface SetupExistingTeamProps {
  setter: (team: NonIDGameTeamI) => void;
  teams: TeamI[];
}
function SetupExistingTeam({
  teams,
  setter,
}: SetupExistingTeamProps): React.JSX.Element {
  const [team, setTeam] = useState<undefined | NonIDGameTeamI>();
  if (!team) {
    return (
      <TeamSelectOptions
        teams={teams}
        title="Existing"
        setTeam={(t) => {
          setTeam({
            ...t,
          });
        }}
      />
    );
  }
  return (
    <>
      <div>{team.name}</div>
      <SetupGameTeamPlayers
        setPlayers={(players) => {
          setTeam({
            ...team,
            members: players,
          });
        }}
        players={team.members}
      />
      <button
        type="button"
        onClick={() => {
          setter(team);
        }}
      >
        Next
      </button>
    </>
  );
}

interface SetupGameTeamProps {
  setter: (team: SetupTeam) => void;
}
function SetupGameTeam({
  setter,
}: SetupGameTeamProps): React.JSX.Element | null {
  useAuth();
  useUser();
  const [statNewOrExistingTeamOrNot, setStatNewOrExistingTeamOrNot] = useState<
    StatNewOrExistingTeamOrNotT | undefined
  >(undefined);

  const teamsService = useTeams();
  if (teamsService.status === 'error') return <>{teamsService.error.message}</>;
  if (teamsService.status === 'not-found') return <>Teams not found</>;
  if (teamsService.status !== 'loaded') return null;
  const teams = teamsService.payload;
  return (
    <>
      {statNewOrExistingTeamOrNot === undefined && (
        <PickNewOrExistingTeamOrNot
          disableSelect={teams.length === 0}
          setter={setStatNewOrExistingTeamOrNot}
        />
      )}
      {statNewOrExistingTeamOrNot === 'existing' && (
        <SetupExistingTeam teams={teams} setter={setter} />
      )}
      {/* {statNewOrExistingTeamOrNot === 'new' &&
            <SetupNewTeam setter={setter} />} */}
      {statNewOrExistingTeamOrNot === 'not' && (
        <SetupNameOnlyTeam setter={setter} />
      )}
      {/* {statNewOrExistingTeamOrNot && (
              <button onClick={() => setStatNewOrExistingTeamOrNot(undefined)}>
                Cancel
              </button>
            )} */}
      {/* {statNewOrExistingTeamOrNot === "existing" && <b>existing</b>}
            {statNewOrExistingTeamOrNot === "not" && <b>not</b>} */}
    </>
  );
}

interface FieldWithError {
  value: string;
  error?: Error;
}
interface AddPlayerRowProps {
  addPlayer: (player: NonIDPlayerI) => void;
}
function AddPlayerRow({ addPlayer }: AddPlayerRowProps): React.JSX.Element {
  const [name, setName] = useState<FieldWithError>({
    value: '',
    error: undefined,
  });
  const [number, setNumber] = useState<FieldWithError>({
    value: '',
    error: undefined,
  });

  const [nameInput, setNameInput] = useState<HTMLInputElement | undefined>(
    undefined,
  );
  const [numberInput, setNumberInput] = useState<HTMLInputElement | undefined>(
    undefined,
  );

  const error = name.error?.message ?? number.error?.message;
  const verifyPlayer = (): undefined | NonIDPlayerI => {
    if (name.value === '') {
      setName({ ...name, error: new Error('Player name is required') });
      nameInput?.focus();
      return;
    }
    if (number.value === '' || isNaN(Number(number.value))) {
      setNumber({ ...number, error: new Error('Number is required') });
      // numberInput?.focus();
      numberInput?.select();
      return;
    }
    return {
      name: name.value,
      number: Number(number.value),
    };
  };

  const resetForm = (): void => {
    setName({ value: '', error: undefined });
    setNumber({ value: '', error: undefined });
  };

  const verifyAndAdd = (): void => {
    const verificationResult = verifyPlayer();
    // Not an error
    if (verificationResult !== undefined) {
      addPlayer(verificationResult);
      resetForm();
      nameInput && nameInput.focus();
    }
  };

  useEffect(() => {
    if (nameInput === undefined) {
      return;
    }
    nameInput.focus();
    return () => {
      resetForm();
    };
  }, [nameInput]);

  return (
    <>
      {' '}
      <tr>
        <td className={name.error ? 'error' : undefined}>
          <input
            type="text"
            ref={(input) => {
              setNameInput(input ?? undefined);
            }}
            placeholder="Player Name"
            required
            name="player"
            value={name.value}
            onKeyDown={(event) => {
              event.key === 'Enter' && verifyAndAdd();
            }}
            onChange={(event) => {
              setName({ ...name, value: event.target.value });
            }}
          />
        </td>
        <td className={number.error ? 'error' : undefined}>
          <input
            type="text"
            ref={(input) => {
              setNumberInput(input ?? undefined);
            }}
            placeholder="Number"
            required
            name="number"
            value={number.value}
            onKeyDown={(event) => {
              event.key === 'Enter' && verifyAndAdd();
            }}
            onChange={(event) => {
              setNumber({ ...number, value: event.target.value });
            }}
          />
        </td>
        <td>
          <button
            type="button"
            onClick={() => {
              // We know this is PlayerI since the button is disabled otherwise
              verifyAndAdd();
            }}
          >
            Add
          </button>
        </td>
      </tr>
      {error !== '' && (
        <tr>
          <td colSpan={2}>{error}</td>
        </tr>
      )}
    </>
  );
}

interface SetupGameTeamPlayersProps {
  players: NonIDPlayerI[];
  setPlayers: (players: NonIDPlayerI[]) => void;
}
export function SetupGameTeamPlayers({
  players,
  setPlayers,
}: SetupGameTeamPlayersProps): React.JSX.Element {
  const addToTeam = (player: NonIDPlayerI): void => {
    setPlayers([...players, player]);
  };
  const removeFromTeam = (removePlayer: NonIDPlayerI): void => {
    setPlayers([...players.filter((player) => player !== removePlayer)]);
  };

  return (
    <table>
      <thead>
        <th>Player Name</th>
        <th>Number</th>
        <th />
      </thead>
      <tbody>
        <AddPlayerRow addPlayer={addToTeam} />

        {sortByPlayerByName(players).map((player) => {
          return (
            <tr key={player.number}>
              <td>{player.name}</td>
              <td>{player.number}</td>
              <td>
                <button
                  type="button"
                  className="as-link"
                  style={{ float: 'right' }}
                  onClick={() => {
                    removeFromTeam(player);
                  }}
                >
                  Remove
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

interface SetupProgresProps {
  activeStep: number;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- save for later
function SetupProgress({ activeStep }: SetupProgresProps): React.JSX.Element {
  const getClass = (innerActiveStep: number, compareStep: number): string => {
    if (innerActiveStep === compareStep) {
      return 'active';
    }
    if (innerActiveStep > compareStep) {
      return 'done';
    }
    return '';
  };

  return (
    <div className="progressbar-container clearfix">
      <ul className="progressbar">
        <li className={getClass(activeStep, 1)}>Select Video</li>
        <li className={getClass(activeStep, 2)}>Home Team</li>
        <li className={getClass(activeStep, 3)}>Away Team</li>
        <li className={getClass(activeStep, 4)}>Setup Done!</li>
      </ul>
    </div>
  );
}

interface StepProps {
  active: number;
  children?: React.ReactNode;
}
function Step1({ active, children }: StepProps): React.JSX.Element {
  if (active === 1) {
    return <>{children}</>;
  }
  return <div />;
}

function Step2({ active, children }: StepProps): React.JSX.Element {
  if (active === 2) {
    return <>{children}</>;
  }
  return <div />;
}
function Step3({ active, children }: StepProps): React.JSX.Element {
  if (active === 3) {
    return <>{children}</>;
  }
  return <div />;
}

function Step4({ active, children }: StepProps): React.JSX.Element {
  if (active === 4) {
    return <>{children}</>;
  }
  return <div />;
}

interface GameSetupSaveProps {
  videoId: string;
  homeTeam: NonIDGameTeamI;
  awayTeam: NonIDGameTeamI;
  onSaved: (game: GameI) => void;
}
export function GameSetupSave({
  videoId,
  homeTeam,
  awayTeam,
}: GameSetupSaveProps): React.JSX.Element {
  const saveHomeTeam = SaveOrUpdateTeam(homeTeam);
  const saveAwayTeam = SaveOrUpdateTeam(awayTeam);
  const [saveGame, setSaveGame] = useState<Service<GameI>>({ status: 'init' });
  const { data: user } = useUser();
  const firestore = useFirestore();
  const userId = user?.uid;

  function status<T>(service: Service<T>): React.JSX.Element | null {
    if (service.status === 'init' || service.status === 'loading') return null;
    if (service.status === 'error')
      return (
        <>
          ❌ <span>{service.error.message}</span>
        </>
      );
    return <>✅</>;
  }
  useEffect(() => {
    if (
      saveGame.status === 'init' &&
      saveHomeTeam.status === 'loaded' &&
      saveAwayTeam.status === 'loaded' &&
      userId !== undefined
    ) {
      setSaveGame({ status: 'loading' });
      const toSaveGame = {
        videoId,
        homeTeam: saveHomeTeam.payload,
        awayTeam: saveAwayTeam.payload,
        timestamp: serverTimestamp(),
        events: [],
        owner: userId,
      };
      addDoc(collection(firestore, 'game'), toSaveGame)
        .then((gameDoc) => {
          const game: GameI = {
            ...toSaveGame,
            id: gameDoc.id,
            timestamp: new Date(),
          };
          setSaveGame({
            status: 'loaded',
            payload: game,
          });
        })
        .catch((error: unknown) => {
          setSaveGame({
            status: 'error',
            error: error as Error,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not sure
  }, [userId, saveHomeTeam, saveAwayTeam, saveGame.status, videoId]);
  return (
    <div>
      Saving
      <span>
        Home Team - {homeTeam.name} {status(saveHomeTeam)}
      </span>
      <span>
        Away Team - {awayTeam.name} {status(saveAwayTeam)}
      </span>
      <span>Game {status(saveGame)}</span>
    </div>
  );
}

export function SaveOrUpdateTeam(
  unsavedTeam: NonIDGameTeamI,
): ServiceSave<GameTeamI> {
  const [gameTeam, setGameTeam] = useState<ServiceSave<GameTeamI>>({
    status: 'loading',
  });
  const { data: user, status: userStatus } = useUser();
  const firestore = useFirestore();
  const teamCollection = collection(firestore, 'team');
  const userId = user?.uid;
  const [debugDeps, setDebugDeps] = useState({
    unsavedTeam,
    userStatus,
    teamCollection,
    userId,
  });

  useEffect(() => {
    if (debugDeps.unsavedTeam !== unsavedTeam) {
      // eslint-disable-next-line no-console -- not sure
      console.log('unsavedteam', unsavedTeam);
    }
    if (debugDeps.userStatus !== userStatus) {
      // eslint-disable-next-line no-console -- not sure
      console.log('userStatus', userStatus);
    }
    if (debugDeps.teamCollection !== teamCollection) {
      // eslint-disable-next-line no-console -- not sure
      console.log('teamCollection', teamCollection);
    }
    if (debugDeps.userId !== userId) {
      // eslint-disable-next-line no-console -- not sure
      console.log('user.uid', userId);
    }
    setDebugDeps({
      unsavedTeam,
      userStatus,
      teamCollection,
      userId,
    });

    if (userStatus !== 'success') {
      throw new Error('SaveOrUpdateTeam should always be invoked logged in');
    }

    // Create team
    if (unsavedTeam.id === undefined) {
      const idMembers: PlayerI[] = unsavedTeam.members.map((player) => {
        return {
          id: player.id ?? uuidv4(),
          ...player,
        };
      });

      const teamWithmemberIDs: NonIDGameTeamI = {
        ...unsavedTeam,
        members: idMembers,
      };

      addDoc(teamCollection, {
        owner: userId,
        ...unsavedTeam,
      })
        .then((teamDoc) => {
          setGameTeam({
            status: 'loaded',
            payload: {
              ...teamWithmemberIDs,
              members: idMembers,
              id: teamDoc.id,
            },
          });
        })
        .catch((error: unknown) => {
          setGameTeam({
            status: 'error',
            error: error as Error,
          });
        });
      // Add members to team
    } else {
      const teamID: string = unsavedTeam.id;
      const players = unsavedTeam.members
        .filter((player) => player.id)
        .filter((player) => {
          const { success } = PlayerISchema.safeParse(player);
          return success;
        });
      const unsavedPlayers: NonIDPlayerI[] = unsavedTeam.members.filter(
        (player) => !player.id,
      );

      if (unsavedPlayers.length === 0) {
        setGameTeam({
          status: 'loaded',
          payload: {
            id: teamID,
            name: unsavedTeam.name,
            members: players as PlayerI[],
          },
        });
      } else {
        const idMembers = unsavedPlayers.map((player) => {
          return {
            ...player,
            id: uuidv4(),
          };
        });
        updateDoc(doc(firestore, 'team', unsavedTeam.id), {
          members: arrayUnion(...idMembers),
        })
          .then(() => {
            setGameTeam({
              status: 'loaded',
              payload: {
                id: teamID,
                name: unsavedTeam.name,
                members: [...players, ...idMembers] as PlayerI[],
              },
            });
          })
          .catch((error: unknown) => {
            setGameTeam({ status: 'error', error: error as Error });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not sure
  }, [unsavedTeam, userStatus, userId]);

  return gameTeam;
}

export function GameSetup(): React.JSX.Element {
  const [videoID, setVideoID] = useState<string | undefined>();
  const [homeTeam, setHomeTeam] = useState<NonIDGameTeamI | undefined>();
  const [awayTeam, setAwayTeam] = useState<NonIDGameTeamI | undefined>();
  const [savedGame, setSavedGame] = useState<GameI>();

  let activeStep = 0;
  if (videoID === undefined) activeStep = 1;
  else if (homeTeam === undefined) activeStep = 2;
  else if (awayTeam === undefined) activeStep = 3;
  else activeStep = 4;

  if (savedGame !== undefined) return <Navigate to={`/game/${savedGame.id}`} />;

  return (
    <div className="game-setup">
      <div className="header">
        <h3>Game Setup</h3>
      </div>
      <div className="content">
        <div>
          <SteppedProgress
            currentStep={activeStep}
            steps={['Select Video', 'Home Team', 'Away Team', 'Done']}
          />
        </div>
        <Step1 active={activeStep}>
          <SelectVideo setVideoID={setVideoID} />
        </Step1>
        <Step2 active={activeStep}>
          <SetupGameTeam setter={setHomeTeam} />
        </Step2>
        <Step3 active={activeStep}>
          <SetupGameTeam setter={setAwayTeam} />
        </Step3>
        <Step4 active={activeStep}>
          {!videoID || !homeTeam || !awayTeam ? (
            <div>
              Error, step4 without required data
              {videoID === undefined && 'missing videoID'}
              {homeTeam === undefined && 'missing homeTeam'}
              {awayTeam === undefined && 'missing awayTeam'}
            </div>
          ) : (
            <GameSetupSave
              videoId={videoID}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              onSaved={(game: GameI) => {
                setSavedGame(game);
              }}
            />
          )}
        </Step4>
      </div>
    </div>
  );
}
