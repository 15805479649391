import { z } from 'zod';
import { SideSchema } from '../models';

export const FoulEventSchema = z.object({
  cat: z.literal('FoulEvent'),
  side: SideSchema,
  player: z.string(),
  fouledOn: z.string().optional(),
});

export type FoulEvent = z.infer<typeof FoulEventSchema>;

export const PersonalFoulEventSchema = FoulEventSchema.extend({
  what: z.literal('PersonalFoul'),
});
export type PersonalFoulEvent = z.infer<typeof PersonalFoulEventSchema>;

export const FoulEventsSchema = PersonalFoulEventSchema;
export type FoulEvents = z.infer<typeof FoulEventsSchema>;
