import {
  FirebaseAppProvider,
  useFirebaseApp,
  FirestoreProvider,
  AuthProvider,
  SuspenseWithPerf,
  useInitPerformance,
} from 'reactfire';
import React from 'react';
import { BrowserRouter, Route, Link, Routes, Navigate } from 'react-router-dom';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { GameList } from './game/gamelist';
import { GameById } from './game/game';
import { GameSetup } from './game/game-setup';
import './app.css';
import { Logo } from './logo';
import { MaybeLogout, Login, AuthWrapper } from './login';
import { TeamList } from './team-list';
import { ErrorBoundary } from './error-boundary';
import { Loading } from './stories/loading';

const firebaseConfig = {
  apiKey: 'AIzaSyCoOFEDWj3HBvGgR84rus85bxWIiUwaGsM',
  authDomain: 'veeball-64f0a.firebaseapp.com',
  projectId: 'veeball-64f0a',
  storageBucket: 'veeball-64f0a.appspot.com',
  messagingSenderId: '1076754649058',
  appId: '1:1076754649058:web:96c335c24fdb13175573bb',
  measurementId: 'G-R230HN0T70',
};

export function AppWrapper(): React.ReactNode {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </FirebaseAppProvider>
  );
}

export function App(): React.ReactNode {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const firestoreInstance = getFirestore(firebaseApp);
  useInitPerformance(
    async (firebaseAppInner) => {
      const { getPerformance } = await import('firebase/performance');
      return getPerformance(firebaseAppInner);
    },
    { suspense: false }, // false because we don't want to stop render while we wait for perf
  );

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={auth}>
        <BrowserRouter>
          <div className="page">
            {/* <div className="overcolor"> */}
            <div className="overcolor">
              <div className="top-bar">
                <Logo />
                <div>
                  <nav>
                    <div className="clearfix">
                      <div>
                        <Link to="/games">
                          <div className="nav-item">Games</div>
                        </Link>
                      </div>
                      <div>
                        <Link to="/game/new">
                          <div className="nav-item">New Game</div>
                        </Link>
                      </div>
                      <MaybeLogout />
                    </div>
                  </nav>
                </div>
              </div>
              <div className="main">
                <div>
                  <Routes>
                    <Route path="/">
                      <Route
                        path="/games"
                        element={
                          <SuspenseWithPerf
                            traceId="firebase-user-wait"
                            fallback={<Loading />}
                          >
                            <AuthWrapper
                              Component={GameList}
                              fallback={<Login />}
                            />
                          </SuspenseWithPerf>
                        }
                      />
                      <Route path="/teams" element={<TeamList />} />
                      <Route path="game">
                        <Route
                          path="new"
                          element={
                            <SuspenseWithPerf
                              traceId="firebase-user-wait"
                              fallback={<Loading />}
                            >
                              <AuthWrapper
                                Component={GameSetup}
                                fallback={<Login />}
                              />
                            </SuspenseWithPerf>
                          }
                        />
                        <Route
                          path=":gameId"
                          element={
                            <SuspenseWithPerf
                              traceId="firebase-user-wait"
                              fallback={<Loading />}
                            >
                              <AuthWrapper
                                Component={GameById}
                                fallback={<Login />}
                              />
                            </SuspenseWithPerf>
                          }
                        />
                      </Route>
                      <Route
                        path="/"
                        element={<Navigate to="games" replace />}
                      />
                    </Route>
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </FirestoreProvider>
  );
}
