import React, { useState, useEffect, type ChangeEvent } from 'react';
import ytLogo from './yt_logo_rgb_light.png';
import './selectvideo.css';
import { H2, H3, P } from './style';

const extracVideoFromYoutubeURL = (url: string): string | undefined => {
  const parsedURL = document.createElement('a');
  parsedURL.href = url;
  if (parsedURL.hostname === 'youtu.be') {
    if (/^\/[0-9a-zA-Z-_]{11}$/.exec(parsedURL.pathname)) {
      return parsedURL.pathname.substr(1);
    }
  }
  if (parsedURL.hostname === 'www.youtube.com') {
    const v = new URLSearchParams(parsedURL.search).get('v');
    if (v?.match(/^[0-9a-zA-Z-_]{11}$/)) {
      return v;
    }
  }
  return undefined;
};

interface SelectVideoProps {
  setVideoID: (video: string) => void;
}

export function SelectVideo({
  setVideoID,
}: SelectVideoProps): React.JSX.Element {
  const [videoIDInput, setVideoIDInput] = useState<
    undefined | HTMLInputElement
  >();
  const [videoIDInputValue, setVideoIDInputValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (videoIDInput !== undefined) {
      videoIDInput.focus();
    }
  }, [videoIDInput]);

  const videoIDUpdate = (event: ChangeEvent<HTMLInputElement>): void => {
    setVideoIDInputValue(event.target.value);
  };

  const validate = (): void => {
    const validVideoID = extracVideoFromYoutubeURL(videoIDInputValue);
    if (validVideoID) {
      setVideoID(validVideoID);
      setError('');
    } else {
      setError(
        'Invalid YouTube Video Link, please paste a link to a video from ' +
          'youtube',
      );
    }
  };

  return (
    <div className="vcontainer">
      <div className="vheader">
        <H2>Upload Video</H2>
        <P>
          Before you can start statting you will need to reference a YouTube
          video, either paste a link to an existing video or upload one to
          YouTube and then paste the link below
        </P>
      </div>
      <div className="vleft">
        <YoutubeUploadButton />
      </div>
      <div className="vright">
        <p>
          <label htmlFor="videoURL">
            <H3>Video Link</H3>
          </label>
        </p>
        {error !== '' && <p className="verror">{error}</p>}
        <input
          name="videoURL"
          placeholder="https://youtu.be/..."
          ref={(input) => {
            setVideoIDInput(input ?? undefined);
          }}
          value={videoIDInputValue}
          onChange={videoIDUpdate}
          onKeyUp={validate}
          onPaste={validate}
        />
      </div>
    </div>
  );
}

function YoutubeUploadButton(): React.JSX.Element {
  return (
    <a target="_blank" href="https://www.youtube.com/upload" rel="noopener">
      <div className="youtube-upload">
        <img className="youtube-logo" src={ytLogo} alt="YouTube logo" />
        <h1>Upload</h1>
      </div>
    </a>
  );
}
