import { createRoot } from 'react-dom/client';
import './index.css';
import React from 'react';
import { AppWrapper } from './app';

const container = document.getElementById('root');

if (!container) throw new Error('root not found');
const root = createRoot(container);
root.render(<AppWrapper />);
