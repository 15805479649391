import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  useAuth,
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useSigninCheck,
  useUser as firebaseUseUser,
} from 'reactfire';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  type Firestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { z } from 'zod';
import { type SavedEvent, type Event, SavedEventSchema } from './event/event';
import { type TimedEvent } from './event/models';
import {
  type GameI,
  GameISchema,
  guardedTeams,
  type ID,
  type TeamI,
  type User,
  type GoogleUser,
} from './models';
import { type Service } from './service';

export const useUser = (): Service<User> => {
  const { data, status, error } = firebaseUseUser();

  const [user, setUser] = useState<Service<User>>({
    status: 'init',
  });

  const userId = data?.uid;
  const isAnonymous = data === null || data.isAnonymous;
  // console.log(
  //   `data ${JSON.stringify(data)} status=${status} error=${
  //     error?.message
  //   } hasEmitted=${hasEmitted}`
  // );

  useEffect(() => {
    if (status === 'loading') {
      setUser({
        status: 'loading',
      });
      return;
    }
    if (status === 'error') {
      setUser({
        status: 'error',
        error: error ?? new Error('unknown user error'),
      });
      return;
    }

    if (isAnonymous) {
      setUser({
        status: 'loaded',
        payload: {
          id: 'anonymous',
          isAnonymous: true,
        },
      });
      return;
    }
    if (userId) {
      setUser({
        status: 'loaded',
        payload: {
          id: userId,
          isAnonymous: false,
        },
      });
    }
  }, [userId, status, error, isAnonymous]);
  return user;
};

export interface GameEventMutations {
  addEvent: (gameID: string, event: Event & TimedEvent) => Promise<void>;
  deleteEvent: (
    gameID: string,
    event: Event & TimedEvent & ID,
  ) => Promise<void>;
}

export const useGame = (
  user: User,
  gameID: string,
): Service<GameI & GameEventMutations> => {
  const firestore = useFirestore();
  const gameRef = doc(firestore, 'game', gameID);
  const userId = user.id;

  const [gameService, setGameService] = useState<
    Service<GameI & GameEventMutations>
  >({
    status: 'loading',
  });
  const { data, status, error } = useFirestoreDocData(gameRef, {
    idField: 'id',
    initialData: {},
  });

  useEffect(() => {
    if (status === 'error') {
      setGameService({
        status: 'error',
        error: error ?? new Error('Unknown error'),
      });
      return;
    }
    if (status === 'loading') {
      setGameService({
        status: 'loading',
      });
      return;
    }
    try {
      const game = GameISchema.parse(data);
      setGameService({
        status: 'loaded',
        payload: {
          ...game,
          addEvent: (gameId: string, event: Event & TimedEvent) =>
            addEvent(firestore, gameId, event),
          deleteEvent: (gameId: string, event: Event & TimedEvent & ID) =>
            deleteEvent(firestore, gameId, event),
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        setGameService({
          status: 'error',
          error: e,
        });
      } else {
        setGameService({
          status: 'error',
          error: new Error(`Invalid error in useGame: ${JSON.stringify(e)}`),
        });
      }
    }
  }, [data, error, status, firestore, userId]);

  return gameService;
};

export const useGames = (
  user: GoogleUser,
): Service<(GameI & GameEventMutations)[]> => {
  const firestore = useFirestore();

  // eslint-disable-next-line no-console -- debug
  console.log('something', JSON.stringify(user));
  const [gameService, setGameService] = useState<
    Service<(GameI & GameEventMutations)[]>
  >({
    status: 'loading',
  });
  const queryRef = query(
    collection(firestore, 'game'),
    where('owner', '==', user.id),
  );
  const { data, status, error } = useFirestoreCollectionData(queryRef, {
    idField: 'id',
  });

  useEffect(() => {
    if (status === 'error') {
      setGameService({
        status: 'error',
        error: error ?? new Error('Unknown error'),
      });
      return;
    }
    if (status === 'loading') {
      setGameService({
        status: 'loading',
      });
      return;
    }
    // eslint-disable-next-line no-console -- debug
    console.log(JSON.stringify(data));
    const result = z.array(GameISchema).safeParse(data);

    if (result.success) {
      const games: GameI[] = result.data;
      setGameService({
        status: 'loaded',
        payload: games.map((game) => {
          return {
            ...game,
            addEvent: (gameId: string, event: Event & TimedEvent) =>
              addEvent(firestore, gameId, event),
            deleteEvent: (gameId: string, event: Event & TimedEvent & ID) =>
              deleteEvent(firestore, gameId, event),
          };
        }),
      });
      return;
    }
    setGameService({
      status: 'error',
      error: new Error(
        `Error loading game list from: ${JSON.stringify(data)}`,
        {
          cause: result.error,
        },
      ),
    });
  }, [data, error, status, firestore]);
  return gameService;
};

const guardedEventCollection = (data: unknown): SavedEvent[] => {
  const result = z
    .array(
      z.object({
        events: z.array(SavedEventSchema),
      }),
    )
    .safeParse(data);
  if (!result.success) {
    throw new Error(`Invalid event collection: ${JSON.stringify(data)}`, {
      cause: result.error,
    });
  }
  return result.data.flatMap((event) => {
    return event.events;
  });
};

export const getBucketNumberByTime = (when: number): number =>
  Math.floor(when / 600 / 1000);

export const deleteEvent = (
  firestore: Firestore,
  gameID: string,
  event: Event & TimedEvent & ID,
): Promise<void> => {
  const bucket = getBucketNumberByTime(event.when).toString();
  const docRef = doc(firestore, `game/${gameID}/event`, bucket);
  // const doc = firestore
  //   .collection('game')
  //   .doc(gameID)
  //   .collection('event')
  //   .doc(bucket);

  const cleanedEvent: unknown = JSON.parse(JSON.stringify(event));
  return updateDoc(docRef, {
    events: arrayRemove(cleanedEvent),
  });
};

export const addEvent = async (
  firestore: Firestore,
  gameID: string,
  event: Event & TimedEvent,
): Promise<void> => {
  const bucket = getBucketNumberByTime(event.when).toString();
  const docRef = doc(firestore, `game/${gameID}/event`, bucket);

  const eventWithID: SavedEvent = {
    ...event,
    id: uuidv4(),
  };
  // Really just making sure it exists
  // we use the JSON functionality to clean undefined
  const cleanedEvent: unknown = JSON.parse(JSON.stringify(eventWithID));
  try {
    await setDoc(
      docRef,
      {
        events: arrayUnion(cleanedEvent),
      },
      {
        merge: true,
      },
    );
  } catch (e: unknown) {
    await setDoc(docRef, {
      events: [cleanedEvent],
    });
  }
};

export function useGameEvents(gameID: string): Service<SavedEvent[]> {
  const firestore = useFirestore();
  const [gameEventService, setGameEventService] = useState<
    Service<SavedEvent[]>
  >({
    status: 'loading',
  });
  const eventCollectionRef = collection(firestore, `game/${gameID}/event`);
  const { data, status, error } =
    useFirestoreCollectionData(eventCollectionRef);

  // eslint-disable-next-line no-console -- fle
  console.log('status', status);
  useEffect(() => {
    if (status === 'error') {
      setGameEventService({
        status: 'error',
        error: error ?? new Error('Unknown error'),
      });
      return;
    }
    if (status === 'loading') {
      setGameEventService({
        status: 'loading',
      });
      return;
    }
    try {
      const events = guardedEventCollection(data);
      setGameEventService({
        status: 'loaded',
        payload: events,
      });
    } catch (e) {
      if (e instanceof Error) {
        setGameEventService({
          status: 'error',
          error: e,
        });
      } else {
        setGameEventService({
          status: 'error',
          error: new Error(
            `Invalid error in useGameEvent: ${JSON.stringify(e)}`,
          ),
        });
      }
    }
  }, [data, error, status]);
  return gameEventService;
}

export const useTeams = (): Service<TeamI[]> => {
  const { status, data } = useSigninCheck();
  const teamService = useTeamsPostAuth();
  if (status === 'error') {
    return {
      status: 'error',
      error: new Error(`Error in sign in: ${JSON.stringify(data.errors)}`),
    };
  }
  if (status === 'loading')
    return {
      status: 'loading',
    };
  if (!data.signedIn)
    return {
      status: 'error',
      cat: 'login-error',
      error: new Error('login required'),
    };
  return teamService;
};

export const useTeamsPostAuth = (): Service<TeamI[]> => {
  const firestore = useFirestore();

  useAuth();
  const user = useUser();

  const [teamService, setTeamService] = useState<Service<TeamI[]>>({
    status: 'loading',
  });

  const queryRef =
    user.status === 'loaded' && !user.payload.isAnonymous
      ? query(
          collection(firestore, 'team'),
          where('owner', '==', user.payload.id),
        )
      : collection(firestore, 'team');

  const { data, status, error } = useFirestoreCollectionData(queryRef, {
    idField: 'id',
  });
  useEffect(() => {
    if (status === 'error') {
      setTeamService({
        status: 'error',
        error: error ?? new Error('Unknown error'),
      });
      return;
    }
    if (status === 'loading') {
      setTeamService({
        status: 'loading',
      });
      return;
    }
    try {
      const teams = guardedTeams(data);
      setTeamService({
        status: 'loaded',
        payload: teams.map((team) => {
          return {
            ...team,
          };
        }),
      });
    } catch (e) {
      if (e instanceof Error) {
        setTeamService({
          status: 'error',
          error: e,
        });
      } else {
        setTeamService({
          status: 'error',
          error: new Error(`Invalid error in useTeam: ${JSON.stringify(e)}`),
        });
      }
    }
  }, [data, error, status, firestore]);
  return teamService;
};
