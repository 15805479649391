import { EventEmitter } from 'eventemitter3';
import { useEffect, useState } from 'react';

const clockEmitter = new EventEmitter();

export const updateClockHandler = (milliseconds: number): void => {
  clockEmitter.emit('update', milliseconds);
};

export const useGameClockSeconds = (): number | undefined => {
  const [gameClock, setGameClock] = useState<number>();
  const gameClockOrZero: number = gameClock ?? 0;
  useEffect(() => {
    const func = (value: number): void => {
      if (Math.abs(gameClockOrZero - value) > 1000) {
        setGameClock(value);
      }
    };
    clockEmitter.on('update', func);
    return () => {
      clockEmitter.off('update', func);
    };
  }, [gameClockOrZero]);

  return gameClock;
};

export const useGameClock100MS = (): number | undefined => {
  const [gameClock, setGameClock] = useState<number>();
  const gameClockOrZero: number = gameClock ?? 0;

  useEffect(() => {
    const func = (value: number): void => {
      if (Math.abs(gameClockOrZero - value) > 100) {
        setGameClock(value);
      }
    };
    clockEmitter.on('update', func);
    return () => {
      clockEmitter.off('update', func);
    };
  }, [gameClockOrZero]);

  return gameClock;
};
