import React, { useState, useEffect, useCallback } from 'react';
import {
  type ActionComponentBaseProps,
  ActionComponent,
  type Shortcut,
} from '../action-component';
import { type GameTeamI, type PlayerI, type Side } from '../../models';
import { PlayerSelect, PlayerSelectOneTeam } from '../../game/player';
import {
  type TwoPointMakeEvent,
  type ThreePointMakeEvent,
} from '../../event/score-event';

interface AssistedMakeProps extends ActionComponentBaseProps {
  label: string;
  event: (
    _side: Side,
    _player: string,
    _assistedBy?: string,
  ) => TwoPointMakeEvent | ThreePointMakeEvent;
  shortcut: Shortcut;
  shortcutActive: boolean;
}

function AssistedMake(props: AssistedMakeProps): React.JSX.Element {
  const { insertEvent, event, doneCallback, label } = props;
  const [saving, setSaving] = useState(false);
  const [player, setPlayer] = useState<PlayerI | undefined>(undefined);
  const [team, setTeam] = useState<GameTeamI | undefined>(undefined);
  const [search, setSearch] = useState<string>('');

  const setGamePlayer = useCallback(
    (_team: GameTeamI, cbPlayer: PlayerI) => {
      setTeam(team);
      setPlayer(cbPlayer);
    },
    [setTeam, setPlayer, team],
  );

  const side: Side = team === props.home ? 'home' : 'away';
  const assistorSelected = useCallback(
    (_?: GameTeamI, assistor?: PlayerI) => {
      if (player === undefined) {
        throw RangeError('player cannot be undefined here');
      }
      if (team === undefined) {
        throw RangeError('team cannot be undefined here');
      }
      if (saving) {
        return;
      }
      setSaving(true);
      void insertEvent(event(side, player.id, assistor?.id)).then(() => {
        doneCallback();
      });
    },
    [side, player, team, saving, doneCallback, event, insertEvent],
  );

  useEffect(() => {
    if (player === undefined || team === undefined) {
      return;
    }
    if (saving) return;
    if (team.members.length < 2) {
      assistorSelected(team, undefined);
    }
  }, [player, team, assistorSelected, saving]);

  if (player === undefined || team === undefined) {
    return (
      <ActionComponent {...props} label={label}>
        <PlayerSelect setPlayer={setGamePlayer} {...props} />
      </ActionComponent>
    );
  }

  if (!team.members.length) {
    return (
      <ActionComponent {...props} label={label}>
        <span>Saving</span>
      </ActionComponent>
    );
  }

  return (
    <ActionComponent {...props} label={label}>
      <PlayerSelect setPlayer={setGamePlayer} {...props} />
      <>
        <input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          ref={(input) => {
            input && input.focus();
          }}
        />

        <PlayerSelectOneTeam
          setPlayer={assistorSelected}
          team={team}
          search={search}
          cancel={{
            func: assistorSelected,
            label: 'No Assist',
          }}
          excludePlayerIds={[player.id]}
          overrideHeader={
            <div className="action-header">
              {player.number} - {player.name} - {team.name} assisted by ...
            </div>
          }
          {...props}
        />
      </>
    </ActionComponent>
  );
}

export function TwoPointMake(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  return (
    <AssistedMake
      shortcutActive={false}
      shortcut={{ key: '2' }}
      label="🏀 Two Points"
      event={(side, gamePlayer, assistedBy) => {
        return {
          cat: 'ScoreEvent',
          what: 'TwoPointMake',
          player: gamePlayer,
          points: 2,
          side,
          assistedBy,
        };
      }}
      {...props}
    />
  );
}

export function ThreePointMake(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  return (
    <AssistedMake
      label="👌 Three Points"
      event={(side, gamePlayer, assistedBy) => {
        return {
          cat: 'ScoreEvent',
          what: 'ThreePointMake',
          player: gamePlayer,
          points: 3,
          side,
          assistedBy,
        };
      }}
      shortcutActive={false}
      shortcut={{ key: '3' }}
      {...props}
    />
  );
}

export function FreeThrowMake(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  const playerSelected: (_team: GameTeamI, _gamePlayer: PlayerI) => void = (
    team,
    gamePlayer,
  ) => {
    const side: Side = team === props.home ? 'home' : 'away';

    const insert = async (): Promise<void> => {
      await props.insertEvent({
        cat: 'ScoreEvent',
        what: 'FreeThrowMake',
        player: gamePlayer.id,
        points: 1,
        side,
      });
      props.doneCallback();
    };
    void insert();
  };
  return (
    <ActionComponent {...props} label="🏀 Freethrow" shortcut={{ key: 'f' }}>
      <PlayerSelect setPlayer={playerSelected} {...props} />
    </ActionComponent>
  );
}
