import React from 'react';
import {
  type ActionComponentBaseProps,
  ActionComponent,
} from '../action-component';
import { type GameTeamI, type PlayerI, type Side } from '../../models';
import { PlayerSelect } from '../../game/player';

export function PersonalFoul(
  props: ActionComponentBaseProps,
): React.JSX.Element {
  const playerSelected: (_team: GameTeamI, _gamePlayer: PlayerI) => void = (
    team,
    gamePlayer,
  ) => {
    const side: Side = team === props.home ? 'home' : 'away';
    void props
      .insertEvent({
        cat: 'FoulEvent',
        what: 'PersonalFoul',
        side,
        player: gamePlayer.id,
      })
      .then(() => {
        props.doneCallback();
      });
  };
  return (
    <ActionComponent {...props} label="🛇 Personal Foul" shortcut={{ key: 'p' }}>
      <PlayerSelect setPlayer={playerSelected} {...props} />
    </ActionComponent>
  );
}
